<script setup>
import BcsDynamicText from '../BcsDynamicText.vue'
import { ref, onMounted, computed, onBeforeUnmount } from 'vue'

const contentLoaded = ref(false)
const nativePlayer = ref(null)
const isMobile = ref(false)

const checkIfMobile = () => {
    isMobile.value = window.innerWidth <= 1030
}

const videoSrc = computed(() => {
    return isMobile.value
        ? '/videos/Bcs Marketing Site Hero Video H264 Med Res Mobile.mp4'
        : '/videos/Bcs Marketing Site Hero Video H264 Med Res.mp4'
})

const onNativeVideoReady = () => {
    if (nativePlayer.value) {
        nativePlayer.value.play()
            .catch(error => console.error('Error playing video:', error))
    }
}

const handleResize = () => {
    const wasMobile = isMobile.value
    checkIfMobile()

    if (wasMobile !== isMobile.value && nativePlayer.value) {
        nativePlayer.value.load()
    }
}

onMounted(() => {
    setTimeout(() => {
        contentLoaded.value = true
    }, 300)
    checkIfMobile()
    window.addEventListener('resize', handleResize)
})

onBeforeUnmount(() => {
    window.removeEventListener('resize', handleResize)
})

const navTo = (item) => {
    const element = document.getElementById(item)
    if (element) {
        const offsetTop = element.getBoundingClientRect().top + window.scrollY - 60
        window.scrollTo({ top: offsetTop, behavior: 'smooth' })
    }
}
</script>

<template>
    <div class="d-flex hero-banner-container align-items-center">
        <div class="text-container">
            <div v-if="contentLoaded" class="content-wrapper">
                <h1>
                    <BcsDynamicText :isLandingPage="true" text="heroBannerTitle" />
                </h1>
                <p>
                    <BcsDynamicText :isLandingPage="true" text="heroBannerSubtitle" />
                </p>

                <div class="buttons-container d-flex">
                    <button class="btn btn-primary" @click="navTo('request-demo')">Request a Demo</button>
                    <button class="btn btn-info" @click="navTo('why-bcspro')">Learn More</button>
                </div>
            </div>
        </div>
        <div class="image-container">
            <div class="video-container">
                <video ref="nativePlayer" autoplay loop muted playsinline class="hero-video" preload="auto"
                    :src="videoSrc" disablePictureInPicture="true" @canplay="onNativeVideoReady" />
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.hero-banner-container {
    display: flex;
    width: 100%;
}

.image-container {
    width: 50%;
    height: auto;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.video-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    /* Aspect ratio 16:9 */
    overflow: hidden;
}

.text-container {
    width: 50%;
    padding: 121px 132px 117px 98px;

    .content-wrapper {
        overflow: hidden;
    }

    h1 {
        font-size: 38px;
        margin-bottom: 7px !important;
        opacity: 0;
        transform: translate3d(0, 50px, 0) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
        transform-style: preserve-3d;
        animation: fadeSlideUp 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
        will-change: opacity, transform;
    }

    p {
        font-size: 18px;
        opacity: 0;
        transform: translate3d(0, 50px, 0) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
        transform-style: preserve-3d;
        animation: fadeSlideUp 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.15s forwards;
        will-change: opacity, transform;
    }

    .buttons-container {
        gap: 10px;
        opacity: 0;
        transform: translate3d(0, 50px, 0);
        animation: fadeSlideUp 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s forwards;

        .btn-primary {
            width: 206px;
        }

        .btn-info {
            width: 178px;
        }

        .btn-primary,
        .btn-info {
            height: 48px;
            font-size: 20px;
        }
    }
}

@keyframes fadeSlideUp {
    0% {
        opacity: 0;
        transform: translate3d(0, 50px, 0) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    }
    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    }
}

.hero-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    border: 0;
    object-fit: cover;
}

@media (max-width: 1030px) {
    .hero-banner-container {
        flex-direction: column;
    }

    .text-container,
    .image-container {
        width: 100%;
        order: 1;
    }

    .text-container {
        padding: 35px 19px 50px 21px;
        order: 2;
        background-color: var(--bs-gray-100);
    }

    .buttons-container {
        flex-direction: column;
    }

    .text-container .buttons-container .btn-primary,
    .text-container .buttons-container .btn-info {
        width: 100%;
    }

    .text-container h1 {
        font-size: 30px;
    }

    .video-container {
        padding-bottom: 56.25%;
        height: auto;
    }
}
</style>