<script setup>
import { onMounted, onBeforeUnmount, watch } from 'vue'
import NavBar from '../shared/components/common/NavBar.vue'
import brandLogo from '@/assets/images/BCS-Branding.svg'
import { useRouter } from 'vue-router'
import HeroBanner from '../shared/components/LandingPage/HeroBanner.vue'
import WhyBcsPro from '../shared/components/LandingPage/WhyBcsPro.vue'
import OurProduct from '../shared/components/LandingPage/OurProduct.vue'
import TestimonialsSection from '../shared/components/LandingPage/TestimonialsSection.vue'
import FoundersSection from '../shared/components/LandingPage/FoundersSection.vue'
import FaqsLandingSection from '../shared/components/LandingPage/FaqsLandingSection.vue'
import RequestADemo from '../shared/components/LandingPage/RequestADemo.vue'
import LandingPageFooter from '../shared/components/LandingPage/LandingPageFooter.vue'
import { useHamburguerMenuStore } from '../shared/stores/HamburguerMenu'
import { ref } from 'vue'
import { storeToRefs } from 'pinia'

const router = useRouter()
const hamburguerMenuStore = useHamburguerMenuStore()
const { isMenuOpen } = storeToRefs(hamburguerMenuStore)
const { closeMenu } = hamburguerMenuStore

const activePage = ref('')

const navItems = [
    { id: 'why-bcspro', label: 'Why BCSPro', target: 'why-bcspro' },
    { id: 'our-product', label: 'Our Product', target: 'our-product' },
    { id: 'testimonials', label: 'Testimonials', target: 'testimonials' },
    { id: 'founders-note', label: 'Founder’s Note', target: 'founders-note' },
    { id: 'FAQs', label: 'FAQs', target: 'FAQs' }
]


const actionItems = [
    {
        id: 'request-demo',
        label: 'Request a Demo',
        target: 'request-demo',
        type: 'button',
        primary: true,
        showMobile: true
    },
    {
        id: 'signin',
        label: 'Sign In',
        target: 'signin',
        type: 'button',
        primary: false,
        showMobile: false
    }
]

const disableScroll = () => {
    document.body.style.overflow = 'hidden';
}

const enableScroll = () => {
    document.body.style.removeProperty('overflow');
}

window.addEventListener('resize', () => {
    if (window.innerWidth > 1030) {
        enableScroll()
        closeMenu()
    }
})

watch(isMenuOpen, (newVal) => {
    if (newVal) {
        disableScroll()
    } else {
        enableScroll()
    }
});

const scrollToElement = (elementId, offset = 0) => {
    const element = document.getElementById(elementId)
    if (element) {
        const offsetTop = element.getBoundingClientRect().top + window.scrollY - offset
        window.scrollTo({ top: offsetTop, behavior: 'smooth' })
        return true
    }
    return false
}

const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
}

const handleNavClick = (item) => {
    activePage.value = item.id
    if (isMenuOpen.value) {
        closeMenu()
        setTimeout(() => {
            scrollToElement(item.target, 80)
        }, 100)
    } else {
        scrollToElement(item.target, 80)
    }
}

const handleLogoClick = () => {
    activePage.value = ''
    if (isMenuOpen.value) {
        closeMenu()
        setTimeout(() => {
            scrollToTop()
        }, 100)
    } else {
        scrollToTop()
    }
}

const closeMenuFromOverlay = () => {
    closeMenu()
}

const handleActionClick = (item) => {
    if (item.id === 'request-demo') {
        if (isMenuOpen.value) {
            closeMenu()
            setTimeout(() => {
                scrollToElement('request-demo')
            }, 100)
        } else {
            scrollToElement('request-demo')
        }
    } else if (item.id === 'signin') {
        closeMenu();
        router.push({ name: 'login' })
    }
}

const handleScroll = () => {
    if (isMenuOpen.value) return;

    const sections = navItems.map(item => {
        const element = document.getElementById(item.target)
        return { id: item.id, element }
    }).filter(section => section.element !== null)

    if (window.scrollY < 100) {
        activePage.value = ''
        return
    }
    const faqsSection = sections.find(section => section.id === 'FAQs')

    if (faqsSection) {
        const faqsRect = faqsSection.element.getBoundingClientRect()
        if (faqsRect.bottom < 500) {
            activePage.value = ''
            return
        }
    }

    const viewportHeight = window.innerHeight
    let currentSection = null
    let maxVisiblePercentage = 0

    sections.forEach(section => {
        const rect = section.element.getBoundingClientRect()
        const visibleTop = Math.max(0, rect.top)
        const visibleBottom = Math.min(viewportHeight, rect.bottom)
        const visibleHeight = Math.max(0, visibleBottom - visibleTop)

        const sectionHeight = rect.height
        const visiblePercentage = (visibleHeight / sectionHeight) * 100

        if ((visiblePercentage > maxVisiblePercentage) ||
            (rect.top <= 100 && rect.bottom >= 100)) {
            maxVisiblePercentage = visiblePercentage
            currentSection = section.id
        }
    })

    if (currentSection) {
        activePage.value = currentSection
    }
}

onMounted(() => {
    window.addEventListener('scroll', handleScroll)
    handleScroll()
    if (isMenuOpen.value) {
        disableScroll();
    }
})

onBeforeUnmount(() => {
    window.removeEventListener('scroll', handleScroll)
    if (isMenuOpen.value) {
        enableScroll()
    }
})
</script>

<template>
    <div class="sales-page">
        <NavBar :activePage="activePage" :logoSrc="brandLogo" logoAlt="BCS Pro logo" :userData="{}" navMode="scroll"
            navStyle="landing" :navItems="navItems" :actionItems="actionItems" @nav-click="handleNavClick"
            @action-click="handleActionClick" @logo-click="handleLogoClick" />

        <section>
            <HeroBanner />
        </section>
        <!-- Why BCSPro -->
        <section id="why-bcspro">
            <WhyBcsPro />
        </section>
        <!-- Our Product -->
        <section id="our-product">
            <OurProduct />
        </section>

        <!-- What People Are Saying -->
        <section id="testimonials">
            <TestimonialsSection />
        </section>
        <!-- Founders -->
        <section id="founders-note">
            <FoundersSection />
        </section>

        <section id="FAQs">
            <FaqsLandingSection />
        </section>

        <section id="request-demo">
            <RequestADemo />
        </section>

        <LandingPageFooter />

    </div>

    <div v-if="isMenuOpen" class="overlay" @click="closeMenuFromOverlay"></div>
</template>

<style>
h3 {
    font-size: 30px;
    font-weight: 500;
}
</style>

<style scoped>
@media (max-width: 1030px) {
    .overlay {
        background-color: rgba(255, 255, 255, 0.8);
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 500;
        right: 0;
        bottom: 0;
        cursor: pointer;
    }

    .sales-page {
        position: relative;
    }
}
</style>