import { defineStore } from 'pinia'
import { inject, ref } from 'vue'
import axios from 'axios'
import { RequestService } from '../services/RequestService'

export const useTemplateVariablesStore = defineStore('templateVariables', () => {
  const { handleErrors } = RequestService()
  const actualDefinitions = ref({})
  const stackDefinitions = ref([])
  const templateData = ref(null)
  const flattenedData = ref({})
  const loading = ref(false)
  const error = ref(null)
  const actualIdDefinition = ref('')
  const dataBaseRoute = inject('realdatabase')

  let fetchPromise = null
  const hasLoaded = ref(false)

  function replaceVariables(text, variables) {
    let result = text
    for (const key in variables) {
      if (variables[key] || variables[key] === 0) {
        result = result.replace(`<${key}>`, variables[key])
      }
    }
    return result
  }

  function flattenTemplateData(obj, result = {}) {
    for (const key in obj) {
      if (obj[key] && typeof obj[key] === 'object') {
        if (obj[key].name && obj[key].default_value) {
          result[obj[key].name] = {
            title: obj[key].caption || '',
            originalBody: obj[key].default_value || '',
            body: obj[key].default_value || ''
          }
        }
        flattenTemplateData(obj[key], result)
      }
    }
    return result
  }

  function setDefinition(id, title, body) {
    actualDefinitions.value[id] = {
      title,
      body
    }
    flattenedData.value[id] = {
      title,
      originalBody: body,
      body
    }
  }

  function getDefinitionBodySync(id, variables = null) {
    const definition = flattenedData.value[id]
    if (!definition) return ''

    if (variables) {
      return replaceVariables(definition.originalBody, variables)
    }
    return definition.body
  }

  async function getDefinitionById(id, title, variables = null, updateActualId = false, isLandingPage = false) {
    try {
      if (!templateData.value && !loading.value) {
        await fetchTemplateData(isLandingPage)
      }

      if (updateActualId) {
        actualIdDefinition.value = id
      }

      if (!id) {
        return {
          title: title || 'Missing value',
          body: 'Missing value for dinamic text component',
          originalBody: 'Missing value for dinamic text component'
        }
      }

      const definition = flattenedData.value[id]
      if (!definition) {
        const errorDefinition = {
          title: 'Missing value',
          originalBody: 'Missing value for dinamic text component',
          body: 'Missing value for dinamic text component'
        }
        return errorDefinition
      }

      return {
        title: definition.title,
        originalBody: definition.originalBody,
        body:
          variables ? replaceVariables(definition.originalBody, variables) : definition.originalBody
      }
    } catch (err) {
      error.value = err
      handleErrors('Error searching template data:', err)
      return {
        title: 'Error',
        originalBody: 'Error searching template data',
        body: 'Error searching template data'
      }
    }
  }

  async function fetchTemplateData(isLandingPage) {
    if (hasLoaded.value || loading.value) {
      return templateData.value
    }
    if (fetchPromise) {
      return fetchPromise
    }
    try {
      loading.value = true
      error.value = null
      fetchPromise = isLandingPage ? getTemplateLandingVariables() : getTemplateVariables()
      templateData.value = await fetchPromise
      flattenedData.value = flattenTemplateData(templateData.value)
      hasLoaded.value = true
      return templateData.value
    } catch (err) {
      error.value = err
      handleErrors('Error fetching template data:', err)
      throw err
    } finally {
      loading.value = false
      fetchPromise = null
    }
  }

  function removeDefinition(id) {
    if (id in actualDefinitions.value) {
      delete actualDefinitions.value[id]
    }
    if (id in flattenedData.value) {
      delete flattenedData.value[id]
    }
  }

  async function getTemplateVariables() {
    try {
      let token = localStorage.getItem('userToken')
      const response = await axios.get(`${dataBaseRoute}/BCS.json?auth=${token}`, {
        withCredentials: false,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      })
      return response.data
    } catch (error) {
      handleErrors('getTemplateVariables', error)
      throw error
    }
  }

  async function getTemplateLandingVariables() {
    try {      
      const response = await axios.get(`${dataBaseRoute}/LANDING_PAGE.json`, {
        withCredentials: false,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      })      
      return response.data
    } catch (error) {
      handleErrors(error)
      throw error
    }
  }

  function resetTemplateData() {
    hasLoaded.value = false
    templateData.value = null
    flattenedData.value = {}
    loading.value = false
    error.value = null
    fetchPromise = null
  }

  return {
    templateData,
    loading,
    error,
    stackDefinitions,
    fetchTemplateData,
    getDefinitionById,
    getDefinitionBodySync,
    setDefinition,
    removeDefinition,
    resetTemplateData
  }
})
