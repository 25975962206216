export function useIntersectionObserver(options = {}) {
    const defaultOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.15
    }
  
    const mergedOptions = { ...defaultOptions, ...options }
  
    const observe = (element, callback) => {
      if (!element) return { disconnect: () => {} }
  
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            callback()
            observer.disconnect()
          }
        })
      }, mergedOptions)
  
      observer.observe(element)
  
      return {
        disconnect: () => observer.disconnect()
      }
    }
  
    return { observe }
  }