<script setup>
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import {
    getDatabase,
    ref as dbRef,
    get as dbGet,
} from 'firebase/database'
import { getApp } from 'firebase/app'
import { handleErrors } from '../../utils/errorHandler'
import { useIntersectionObserver } from '../../services/IntersectionObserver'

const { observe } = useIntersectionObserver()
const router = useRouter()
const db = getDatabase(getApp())
const socialMedia = ref([])
const animationTriggered = ref(false)
const footerRef = ref(null)

onMounted(async () => {
    await getSocialMedia()
    observe(footerRef.value, () => {
        if (!animationTriggered.value) {
            animationTriggered.value = true
        }
    })
})


const getSocialMedia = async () => {
    try {
        const socialMediaRef = dbRef(db, 'SOCIAL_MEDIA')
        const snapshot = await dbGet(socialMediaRef)
        if (snapshot.exists()) {
            socialMedia.value = snapshot.val()
        }
    } catch (err) {
        handleErrors('Error fetching social media:', err)
    }
}

const scrollToElement = (elementId, offset = 0) => {
    const element = document.getElementById(elementId)
    if (element) {
        const offsetTop = element.getBoundingClientRect().top + window.scrollY - offset
        window.scrollTo({ top: offsetTop, behavior: 'smooth' })
        return true
    }
    return false
}
</script>

<template>
    <div ref="footerRef" class="main-container bg-light border-top">
        <div class="container-fluid footer-container mx-0 px-3 pt-4 pb-3">
            <footer>
                <div class="footer-links-section">
                    <ul class="nav footer-column">
                        <li class="nav-item animate-element" :class="{ 'animate-active': animationTriggered }"
                            style="--delay: 0.1s">
                            <a class="hand-cursor nav-link" @click="scrollToElement('request-demo')">
                                Request a Demo
                            </a>
                        </li>
                        <li class="nav-item animate-element" :class="{ 'animate-active': animationTriggered }"
                            style="--delay: 0.2s">
                            <a class="hand-cursor nav-link sign-in" @click="router.push({ name: 'login' })">
                                Sign In
                            </a>
                        </li>
                        <li class="nav-item why-bcs animate-element" :class="{ 'animate-active': animationTriggered }"
                            style="--delay: 0.3s">
                            <a class="hand-cursor nav-link" @click="scrollToElement('why-bcspro')">
                                Why BCS Pro
                            </a>
                        </li>
                    </ul>

                    <ul class="nav footer-column">
                        <li class="nav-item animate-element" :class="{ 'animate-active': animationTriggered }"
                            style="--delay: 0.4s">
                            <a class="hand-cursor nav-link" @click="scrollToElement('our-product')">
                                Our Product
                            </a>
                        </li>
                        <li class="nav-item animate-element" :class="{ 'animate-active': animationTriggered }"
                            style="--delay: 0.5s">
                            <a class="hand-cursor nav-link" @click="scrollToElement('testimonials')">
                                Testimonials
                            </a>
                        </li>
                        <li class="nav-item founders-note animate-element"
                            :class="{ 'animate-active': animationTriggered }" style="--delay: 0.6s">
                            <a class="hand-cursor nav-link" @click="scrollToElement('founders-note')">
                                A Note from the Founders
                            </a>
                        </li>
                    </ul>

                    <ul class="nav footer-column">
                        <li class="nav-item animate-element" :class="{ 'animate-active': animationTriggered }"
                            style="--delay: 0.7s">
                            <a class="hand-cursor nav-link" @click="scrollToElement('FAQs')">
                                Frequently Asked Questions
                            </a>
                        </li>
                    </ul>
                </div>

                <div class="social-links-section">
                    <ul class="social-list">
                        <li v-for="(social, index) in socialMedia" :key="index" class="social-item animate-element"
                            :class="{ 'animate-active': animationTriggered }" :style="`--delay: ${0.8 + (index * 0.1)}s`">
                            <a class="hand-cursor" target="_blank" :href="social.url" :aria-label="social.name">
                                <i :class="['bi', social.icon, 'social-icon']"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </footer>

            <div class="copyright-section animate-element" :class="{ 'animate-active': animationTriggered }"
                style="--delay: 1.1s">
                <p class="copyright-text">
                    © {{ new Date().getFullYear() }} Benefits Compliance Solutions. Patent Pending. All rights reserved.
                </p>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.animate-element {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease, transform 0.5s ease;
    transition-delay: var(--delay, 0s);
}

.animate-element.animate-active {
    opacity: 1;
    transform: translateY(0);
}

.footer-container {
    padding: 30px 48px;
}

footer {
    display: flex;
    justify-content: space-between;
}

.footer-links-section {
    display: flex;
    gap: 60px;
}

.footer-column {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.nav-link {
    padding: 0;
    font-size: 14px;
}

.nav-link:hover {
    color: rgb(var(--bs-link-hover-color-rgb)) !important;
}

.social-links-section {
    display: flex;
    align-items: flex-start;
}

.social-list {
    display: flex;
    list-style: none;
    gap: 16px;
    padding: 0;
    margin: 0;
}

.social-icon {
    font-size: 22px;
}

.copyright-section {
    margin-top: 24px;
}

.copyright-text {
    font-size: 12px;
    color: #333;
    margin: 0;
}

@media (max-width: 767px) {
    .footer-container {
        padding: 30px 15px;
    }

    .nav-link:hover {
        color: initial !important;
    }

    .main-container {
        padding: 0 !important;
    }

    footer {
        flex-direction: column;
    }

    .footer-links-section {
        flex-direction: column;
        gap: 16px;
    }

    .sign-in {
        color: #A3ACB1;
        cursor: none;
        pointer-events: none;
    }

    .footer-column {
        gap: 16px;
    }

    .nav-link {
        font-size: 18px;
    }

    .privacy-policy {
        display: none;
    }

    .why-bcs {
        display: none;
    }

    .social-links-section {
        margin-top: 24px;
    }

    .copyright-section {
        margin-top: 24px;
    }
}
</style>