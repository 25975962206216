<script setup>
import { onMounted, ref } from 'vue'
import {
    getDatabase,
    ref as dbRef,
    get as dbGet,
} from 'firebase/database'
import { getApp } from 'firebase/app'
import { handleErrors } from '../../utils/errorHandler'
import TextWithDefinitions from '../TextWithDefinitions.vue'
import { FirebaseStorageService } from '../../services/FirebaseStorageService'
const { getImageUrl } = FirebaseStorageService()

const db = getDatabase(getApp())
const whyBcsProItems = ref([])

onMounted(async () => {
    await getItems()
})


const getItems = async () => {
    try {
        const itemsRef = dbRef(db, 'LANDING_PAGE/WhyBCSPro/items')
        const snapshot = await dbGet(itemsRef)
        if (snapshot.exists()) {
            const items = snapshot.val()
            whyBcsProItems.value = await Promise.all(items.map(async (item) => ({
                ...item,
                imageUrl: await getImageUrl('sales-page/whyBcsPro', item.image)
            })))
        }
    } catch (err) {
        handleErrors('Error fetching items:', err)
    }
}



</script>

<template>
    <div class="why-bcs-container">
        <h3 class="text-center">Why Choose BCSPro?</h3>
        <div class="items-container d-flex">
            <div class="text-center item" v-for="item in whyBcsProItems" :key="item.title">
                <div class="image-container d-flex justify-content-center align-items-center">
                    <img :src="item.imageUrl" :alt="item.title">

                </div>
                <div class="title">{{ item.title }}</div>
                <div class="subtitle">
                    <TextWithDefinitions :key="item.subtitle" :text="item.subtitle" />
                </div>
            </div>
        </div>
    </div>
</template>


<style scoped>
.why-bcs-container {
    height: 421px;
    background-color: var(--bs-gray-200);
    padding: 84px 62px 87px 99px;

    h3 {
        margin-bottom: 38px;
    }
}

.image-container {
    width: 81px;
    height: 81px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.items-container {
    gap: 53px;
    align-items: stretch;

    .title {
        font-size: 20px;
        font-weight: 500;
        margin-top: 13px;
        margin-bottom: 12px;
    }

    .subtitle {
        font-size: 14px;
        font-weight: 400;
    }

    .item {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
}


@media (max-width: 1030px) {
    .why-bcs-container {
        height: auto;
        padding: 40px 20px;
    }

    .items-container {
        flex-direction: column;
    }

    .why-bcs-container h3 {
        font-size: 26px;
    }

    .items-container .title {
        font-size: 22px;
    }

    .items-container .subtitle {
        font-size: 18px;
    }
}
</style>
