<script setup>
import { onMounted, ref } from 'vue'
import {
    getDatabase,
    ref as dbRef,
    get as dbGet,
} from 'firebase/database'
import { getApp } from 'firebase/app'
import { handleErrors } from '../../utils/errorHandler'
import TextWithDefinitions from '../TextWithDefinitions.vue'
import { FirebaseStorageService } from '../../services/FirebaseStorageService'
const { getImageUrl } = FirebaseStorageService()

const db = getDatabase(getApp())
const founders = ref([])
const sectionVisible = ref(false)
const sectionRef = ref(null)
let observer = null

onMounted(async () => {
    await getFounders()
    observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
            // Only trigger animation once when the element becomes visible
            if (entry.isIntersecting && !sectionVisible.value) {
                sectionVisible.value = true
                // Stop observing the element once it's visible to save resources
                if (entry.target) {
                    observer.unobserve(entry.target)
                }
            }
        })
    }, { threshold: 0.3 })
    if (sectionRef.value) {
        observer.observe(sectionRef.value)
    }
})

const getFounders = async () => {
    try {
        const foundersRef = dbRef(db, 'LANDING_PAGE/Founders/items')
        const snapshot = await dbGet(foundersRef)
        if (snapshot.exists()) {
            const items = snapshot.val()
            founders.value = await Promise.all(items.map(async (item) => ({
                ...item,
                imageUrl: await getImageUrl('sales-page/founders', item.image)
            })))
        }
    } catch (err) {
        handleErrors('Error fetching founders:', err)
    }
}

</script>

<template>
    <div ref="sectionRef" class="founders-section-container">

        <div class="d-flex founders">
            <div v-for="(founder, index) in founders" :key="founder.id" :class="[
                'founders-content',
                { 'animate-active': sectionVisible },
                index === 0 ? 'animate-slide-from-right' : 'animate-slide-from-left'
            ]" :style="`--delay: ${0.3 + (index * 1)}s`">
                <div class="d-flex image-container justify-content-center">
                    <img class="founder-image" :src="founder.imageUrl" :alt="`founder-image-${index}`">
                </div>

                <div class="founder-content d-flex flex-column align-items-center">
                    <div class="quote">
                        "
                        <TextWithDefinitions :key="founder.quote" :text="founder.quote" />"
                    </div>

                    <div class="founder-name">
                        {{ founder.name }}
                    </div>

                    <div class="role">
                        {{ founder.role }}
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<style scoped lang="scss">
.founders-section-container {
    height: 896px;
    overflow: hidden;

    .founders {
        padding: 85px 139px 85px 140px;

        .founders-content:first-child {
            border-right: 1px solid rgba(33, 37, 41, 0.25);
        }

        &-content {
            width: 50%;
            padding: 0 70px;
            display: flex;
            flex-direction: column;
        }

        .founder-image {
            border-radius: 50%;
        }

        .quote {
            margin-top: 17px;
            margin-bottom: 20px;
            max-width: 400px;
            flex-grow: 1;
        }

        .image-container {
            margin-top: 61px;
        }
    }
}

.founder-name {
    font-size: 18px;
    font-weight: 500;
}

.founder-content {
    text-align: initial;
    align-items: normal !important;
    display: flex;
    flex-direction: column;
    height: 100%;
    /* Ensure full height */
}

.role {
    color: #8C8C8C;
    font-weight: 500;
    font-size: 14px;
    padding-top: 8px;
    padding-bottom: 64px;
}

.animate-element {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.8s ease, transform 0.8s ease;
    transition-delay: var(--delay, 0s);
}

.animate-slide-from-right {
    opacity: 0;
    transform: translateX(60px);
    transition: opacity 0.8s ease, transform 0.8s ease;
    transition-delay: var(--delay, 0.3s);
}

.animate-slide-from-left {
    opacity: 0;
    transform: translateX(-60px);
    transition: opacity 0.8s ease, transform 0.8s ease;
    transition-delay: var(--delay, 0.3s);
}

.animate-active {
    opacity: 1;
    transform: translateY(0) translateX(0);
}

@media (max-width: 1030px) {

    .founders-section-container {
        height: auto;
    }

    .quote,
    .role {
        font-size: 18px;
    }


    .founders-section-container .founders-content {
        width: 100% !important;
        padding: 0 !important
    }

    .founder-content {
        text-align: initial;
        align-items: normal !important;
    }

    .founder-image {
        width: 98%;
    }

    .role {
        padding-bottom: 48px;
    }

    .founders {
        flex-direction: column;
    }

    .founders-section-container .founders {
        padding: 0 20px !important;
    }

    .founders-content {
        width: 100%;
    }

    .founders-section-container .founders .founders-content:first-child {
        border-right: none;
        border-bottom: 1px solid rgba(33, 37, 41, 0.25);
    }
}
</style>