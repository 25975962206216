<script setup>
import { onMounted, ref, computed } from 'vue'
import {
    getDatabase,
    ref as dbRef,
    get as dbGet,
} from 'firebase/database'
import { getApp } from 'firebase/app'
import { handleErrors } from '../../utils/errorHandler'
import TextWithDefinitions from '../TextWithDefinitions.vue'
import BcsAccordion from '../BcsAccordion.vue'
import { useIntersectionObserver } from '../../services/IntersectionObserver'

const { observe } = useIntersectionObserver()
const db = getDatabase(getApp())
const faqs = ref([])
const faqSections = ref([])
const faqsAnimationTriggered = ref(false)
const sectionRef = ref(null)

onMounted(async () => {
    await getFaqs()
    observe(sectionRef.value, () => {
        if (!faqsAnimationTriggered.value) {
            faqsAnimationTriggered.value = true
        }
    }, {
        root: null,
        rootMargin: '0px',
        threshold: 0.60
    })
})


const getFaqs = async () => {
    try {
        const faqsRef = dbRef(db, 'LANDING_PAGE/FAQs')

        const snapshot = await dbGet(faqsRef)
        if (snapshot.exists()) {
            faqs.value = snapshot.val()
            faqSections.value = Object.entries(faqs.value).map(([key, section]) => ({
                ...section,
                questionTitle: key,
                FaqList: section.FaqList ? JSON.parse(section.FaqList) : []
            }))
        }
    } catch (err) {
        handleErrors('Error fetching faqs:', err)
    }
}

const accordionSections = computed(() => {
    return faqSections.value.map(section => ({
        id: section.sectionId,
        title: section.sectionName,
        items: section.FaqList.map(faq => ({
            id: faq.MIGX_id,
            title: faq.faqTitle,
            content: faq.faqBody,
            MIGX_id: faq.MIGX_id,
            sectionId: section.sectionId
        }))
    }))
})
</script>

<template>
    <div ref="sectionRef" class="faqs-landing-section">
        <h3 class="text-center">Frequently Asked Questions</h3>

        <div class="container">
            <div class="row justify-content-center">
                <div class="col-8 accordion-content">
                    <BcsAccordion :isLandingPage="true" :externalAnimationTrigger="faqsAnimationTriggered"
                        :showSectionTitle="false" :sections="accordionSections" sectionTitleClass="header-22"
                        itemTitleClass="text-primary" itemContentClass="faqs-accordion">
                        <template #item-content="{ item }">
                            <TextWithDefinitions :key="item.content" :text="item.content" />
                        </template>
                    </BcsAccordion>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.faqs-landing-section {
    min-height: 573px;
    background-color: var(--bs-gray-200);
    padding-bottom: 85px;

    h3 {
        font-size: 30px;
        font-style: normal;
        font-weight: 500;
        padding-top: 85px;
        padding-bottom: 40px;
    }

    .faqs-accordion {
        font-size: 14px;
    }
}

@media (max-width: 1030px) {
    .faqs-landing-section {
        min-height: auto;
        padding-bottom: 30px;
    }

    .accordion-content {
        width: 100% !important;
    }

    .faqs-landing-section h3 {
        padding-top: 45px;
        padding-bottom: 30px;
        font-size: 26px;
    }

    :deep {
        .faqs-accordion {
            font-size: 18px !important;
        }
    }

    :deep .accordion-button {
        font-size: 18px !important;
    }
}
</style>