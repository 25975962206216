import { ref as storageRef, uploadString, getDownloadURL, deleteObject, getStorage } from 'firebase/storage'
import { getApp } from 'firebase/app'
import { handleErrors } from '../utils/errorHandler'
import { useProfileStore } from '../stores/ProfileStore'
import { inject } from 'vue'

export const FirebaseStorageService = () => {
  const storage = getStorage(getApp())
  const profileStore = useProfileStore()
  const { showAlert, hideAlert } = profileStore
  const functionsBaseUrl = inject('functionsBaseUrl')

  const uploadImage = async (folder, filename, imageData) => {
    try {
      const base64Data = imageData.split(',')[1]
      const photoRef = storageRef(storage, `${folder}/${filename}`)
      await uploadString(photoRef, base64Data, 'base64', {
        contentType: 'image/png'
      })
      return await getDownloadURL(photoRef)
    } catch (error) {
      handleErrors('Error uploading image:', error)
    }
  }

  const deleteOldImage = async (folder, filename) => {
    try {
      const photoRef = storageRef(storage, `${folder}/${filename}`)
      await deleteObject(photoRef)
    } catch (error) {
      if (error.code !== 'storage/object-not-found') {
        handleErrors('Error deleting old image:', error)
      }
    }
  }


  const getImageUrl = async (folder, filename) => {
    try {
      const photoRef = storageRef(storage, `${folder}/${filename}`)
      return await getDownloadURL(photoRef)
    } catch (error) {
      if (error.code === 'storage/object-not-found') {
        return null
      }
      handleErrors('Error getting image:', error)
      throw error
    }
  }

  const updateImage = async (folder, filename, imageData) => {
    try {
      const oldPhotoURL = await getImageUrl(folder, filename)
      if (oldPhotoURL) {
        await deleteOldImage(folder, filename)
      }
      const photoURL = await uploadImage(folder, filename, imageData)
      showAlert()
      return photoURL
    } catch (error) {
      hideAlert()
      handleErrors('Error in updateImage:', error)
      throw error
    }
  }

  const getImageAsBase64 = async (folder, filename) => {
    try {
      const url = `${functionsBaseUrl}/imageToBase64?folder=${folder}&filename=${filename}`
      const response = await fetch(url)
      const result = await response.json()
      return result.data
    } catch (error) {
      if (error.code === 'storage/object-not-found') {
        return null
      }
      handleErrors('Error getting image as base64:', error)
    }
  }

  const getVideoUrl = async (folder, filename) => {
    try {
      const videoRef = storageRef(storage, `${folder}/${filename}`)
      return await getDownloadURL(videoRef)
    } catch (error) {
      if (error.code === 'storage/object-not-found') {
        return null
      }
      handleErrors('Error getting video:', error)
    }
  }

  return {
    uploadImage,
    deleteOldImage,
    updateImage,
    getImageUrl,
    getImageAsBase64,
    getVideoUrl
  }
}