<script setup>
import {
  ref,
  provide,
  inject,
  onBeforeMount,
  onMounted,
  watch,
  reactive,
  computed,
  onBeforeUnmount
} from 'vue'
import { useNavigationStore } from '../shared/stores/NavigationStore'
import { useRouter } from 'vue-router'
import { RequestService } from '../shared/services/RequestService'
import ExistingEmployerDashboard from '../shared/components/ExistingEmployerDashboard/ExistingEmployerDashboard.vue'
import BcsFaqs from '../shared/components/BcsFaqs.vue'
import BcsGlossary from '../shared/components/BcsGlossary.vue'
import HelpAndResources from '../shared/components/HelpAndResources.vue'
import Footer from '../shared/components/FooterSection.vue'
import Faqs from '../shared/components/FaqsSection.vue'
import { vMaska } from 'maska'
import { useForm } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/yup'
import { object, string, date } from 'yup'
import BcsDynamicText from '../shared/components/BcsDynamicText.vue'
import { storeToRefs } from 'pinia'
import Offcanvas from '../shared/components/OffCanvas.vue'
import CenterDefinitionModal from '../shared/components/common/CenterDefinitionModal.vue'
import { useQuestionsStore } from '../shared/stores/QuestionsStore'
import { useEmployerStore } from '../shared/stores/EmployerStore'
import { useSessionStorageStore } from '../shared/stores/SessionStorage'
import TermsAndConditions from '../shared/components/TermsAndConditions.vue'
import { useTemplateVariablesStore } from '../shared/stores/TemplateVariablesStore'
import { useGlossaryStore } from '../shared/stores/GlossaryStore'
import brandLogo from '@/assets/images/BCS-Branding.svg'
import {
  getDatabase,
  ref as dbRef,
  get as dbGet,
} from 'firebase/database'
import { getApp } from 'firebase/app'
import {
  ACTIVITY_NEWEST_TO_OLDEST,
  ACTIVITY_OLDEST_TO_NEWEST,
  formatDateToSQLInChicagoTime,
  ORDER_A_TO_Z,
  ORDER_NEWEST_TO_OLDEST,
  ORDER_OLDEST_TO_NEWEST,
  ORDER_Z_TO_A
} from '../shared/utils'
import { useAuthStore } from '../shared/stores/AuthStore'
import BcsMemberProfile from '../shared/components/Profile/BcsMemberProfile.vue'
import { getAuth } from 'firebase/auth'
import { FirebaseStorageService } from '../shared/services/FirebaseStorageService'
import BcsContactModal from '../shared/components/Contact/BcsContactModal.vue'
import BcsSearchDropdown from '../shared/components/BcsSearchDropdown.vue'
import NavBar from '../shared/components/common/NavBar.vue'


const auth = getAuth()
const navigationStore = useNavigationStore()
const authStore = useAuthStore()
const { customNavigation } = storeToRefs(navigationStore)
const { refNavigate } = storeToRefs(navigationStore)
const router = useRouter()
const { getImageUrl, getImageAsBase64 } = FirebaseStorageService()
provide('customNavigation', customNavigation)
const db = getDatabase(getApp())
const uid = localStorage.getItem('userUid')

const realDataUser = ref({})
const allEmployersList = ref([])
const isLoggingOut = ref(false)
const searchActive = ref(false)
const searchResults = ref([])

const orderOptions = [
  {
    label: 'Activity Newest to Oldest',
    value: ACTIVITY_NEWEST_TO_OLDEST,
    sortDirection: 'DESC',
    sortField: 'log'
  },
  {
    label: 'Activity Oldest to Newest',
    value: ACTIVITY_OLDEST_TO_NEWEST,
    sortDirection: 'ASC',
    sortField: 'log'
  },
  { label: 'Employer Name A-Z', value: ORDER_A_TO_Z, sortDirection: 'ASC', sortField: 'name' },
  { label: 'Employer Name Z-A', value: ORDER_Z_TO_A, sortDirection: 'DESC', sortField: 'name' },
  {
    label: 'Date Added Newest to Oldest',
    value: ORDER_NEWEST_TO_OLDEST,
    sortDirection: 'DESC',
    sortField: 'creation_date'
  },
  {
    label: 'Date Added Oldest to Newest',
    value: ORDER_OLDEST_TO_NEWEST,
    sortDirection: 'ASC',
    sortField: 'creation_date'
  }
]

const { createLog, handleErrors } = RequestService()

const emitter = inject('emitter')

emitter.on('toggleLoading', (value) => {
  loading.value = value.value
})

const employerStore = useEmployerStore()
const sessionStorageStore = useSessionStorageStore()
const templateStore = useTemplateVariablesStore()
const glossaryStore = useGlossaryStore()

const { actualEmployer, actualPlanYear, userData } = storeToRefs(employerStore)
const { getEvent, cleanAllEvents } = sessionStorageStore
const {
  getEmployer,
  createEmployer,
  resetAll: resetAllEmployerStore,
  updateEmployer
} = employerStore

const { fetchTemplateData } = templateStore
const { fetchGlossaryData } = glossaryStore

const { defineField, isFieldValid, isFieldTouched, errors, setValues, setTouched } = useForm({
  validationSchema: toTypedSchema(
    object({
      name: string().min(4).required(),
      ein: string()
        .required()
        .matches(/^[0-9]{2}-[0-9]{7}$/),
      plan_start_date: date()
        .typeError('Please enter a valid date')
        .required()
        .test('is-date-complete', 'Invalid date format', () => {
          if (!plan_start_date.value) return true
          const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/g
          return dateRegex.test(plan_start_date.value)
        }),
      contact_name: string().required(),
      contact_emails: string()
        .required()
        .test('is-email-list', 'Invalid email list format', (value) => {
          if (!value) return true
          const emails = value.split(',').map((email) => email.trim())
          return emails.every((email) => {
            // eslint-disable-next-line no-useless-escape
            const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
            return emailRegex.test(email.trim())
          })
        })
    })
  )
})

const hasErrors = computed(() => {
  return Object.keys(errors.value).length > 0
})

const profilePhoto = computed(() =>
  userData.value?.temporaryPhotoURL !== '/user_silhouette.png' && userData.value?.temporaryPhotoURL !== undefined
    ? userData.value.temporaryPhotoURL
    : userData.value?.profilePhotoUrl
      ? userData.value.profilePhotoUrl
      : userData.value?.logoBase64
        ? userData.value.logoBase64
        : null
)

const getUserRealData = async () => {
  try {
    const uid = localStorage.getItem('userUid')
    const dropdownRef = dbRef(db, `USERS/${uid}`)
    const snapshot = await dbGet(dropdownRef)

    if (snapshot.exists()) {
      realDataUser.value = {
        ...snapshot.val(),
        creationTime: auth.currentUser?.metadata?.creationTime,
        lastLogin: auth.currentUser?.metadata?.lastSignInTime
      }
    } else {
      throw new Error('User data not found in database')
    }
  } catch (err) {
    handleErrors('Error fetching getUserRealData:', err)
    realDataUser.value = {}
  }
}

const hasEmptyValues = computed(() => {
  return (
    !name.value ||
    !ein.value ||
    !plan_start_date.value ||
    !contact_name.value ||
    !contact_emails.value
  )
})

const logout = async () => {
  try {
    isLoggingOut.value = true
    userData.value = {}
    resetAllEmployerStore()
    resetQuestionStore()
    await authStore.logout()
    customNavigation.value.currentPage = 'home'
    cleanAllEvents()
    window.history.replaceState({}, document.title, window.location.pathname)
    await router.replace({ name: 'login' })
  } catch (error) {
    handleErrors('logout', error)
  }
}
const profilePhotoUrl = ref('')
const questionStore = useQuestionsStore()
const { resetQuestionStore } = questionStore
const [name, nameAttrs] = defineField('name')
const [ein, einAttrs] = defineField('ein')
const [plan_start_date, planStartDateAttrs] = defineField('plan_start_date')
const [contact_name, primaryContactNameAttrs] = defineField('contact_name')
const [contact_emails, primaryContactEmailAttrs] = defineField('contact_emails')
//const customNavigation = inject('customNavigation')

const employerList = ref([])
const employerTotalCount = ref(0)
const employerAmountToShow = ref(10)
const inProgress = ref(0)
const loading = ref(false)
const messageForLoading = ref(null)
const rawMaskVal = reactive({})
const orderBySelected = ref(orderOptions[4])
const createUpdateEmployerModal = ref(null)
const searchDropdownRef = ref(null)
const checkDuplicateEmployer = ref(false)
const duplicateEmployerMatch = ref('')
const isNewEmployerAdded = ref({
  state: false,
  id: null
})

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
})

const { simpleGet } = RequestService()
const getPageTitle = (routePath) => {
  if (!routePath) return ''
  
  // If the route contains an employer ID (contains '/')
  if (routePath.includes('/')) {
    const pageParts = routePath.split('/')

    const pagePart = pageParts[pageParts.length - 1]

    // Find the subpage title in DetailEmployer children
    const childRoute = customNavigation.value.routes.DetailEmployer.children.find(
      child => child.name === pagePart
    )
    
    if (childRoute) {
      return childRoute.title
    }
  }
  
  // Search in all main routes
  for (const route of Object.values(customNavigation.value.routes)) {
    if (route.name === routePath) {
      return route.title
    }
  }
  
  return routePath
}

onBeforeMount(async () => {
  //legacy code from origina double app
  //*********************************************** */
  if (window.history && history.pushState) {
    history.pushState('back', null)
    history.pushState('current', null)
    history.pushState('forward', null)
    history.go(-1)
    window.onpopstate = function (event) {
      let state = event.state
      if (state != 'current') {
        event = document.createEvent('Event')
        event.initEvent(state == 'forward' ? 'next' : 'previous', true, true)
        this.dispatchEvent(event)
        if (state == 'forward') {
          history.go(-1)
        } else {
          history.go(1)
        }
      }
    }
  }

  window.addEventListener(
    'next',
    function () {
      customNavigation.value.goForward()
    },
    false
  )

  window.addEventListener(
    'previous',
    function () {
      customNavigation.value.goBack()
    },
    false
  )
  //*********************************************** */
  await authStore.checkAuth()
  const isSubpage = getEvent('isSubpage')
  const route = getEvent('route')
  const subroute = getEvent('subroute')
  const paramsId = getEvent('routeParamsId')
  loading.value = false
  if (!isSubpage && isSubpage !== null) {
    navigateToCurrentRoute(route)
  }
  if (isSubpage !== null) {
    if (isSubpage) {
      const data = {
        route,
        subroute,
        paramsId
      }
      await navigateCurrentSubroute(data)
      loading.value = false
    }
  }
  await loadProfilePhoto()
  await getUserRealData()
  await getUserData()
  await getAllEmployers()
  await getEmployerList()
})

const navigateToCurrentRoute = (route) => {
  customNavigation.value.navigate({
    newPage: route
  })
}

const navigateCurrentSubroute = async (data) => {
  customNavigation.value.navigate({
    newPage: data.route,
    subPage: data.subroute,
    params: { employerId: data.paramsId }
  })
}

onMounted(async () => {
  await fetchTemplateData()
  await fetchGlossaryData()
  createUpdateEmployerModal.value?.addEventListener('show.bs.modal', () => {
    if (actualEmployer.value) {
      fillFormWithEmployerData()
    } else {
      cleanFormNewUser()
    }
  })
  if (sessionStorage.getItem('userAlreadyEnterInCalendar') === null) {
    sessionStorage.setItem('userAlreadyEnterInCalendar', false)
  }
})

onBeforeUnmount(() => {
  const onBackButtonEvent = () => { }
  window.removeEventListener('popstate', onBackButtonEvent)
})

const loadProfilePhoto = async () => {
  try {
    const photoURL = await getImageUrl('profile-photos', `${uid}.png`)
    if (photoURL) {
      profilePhotoUrl.value = photoURL
    }
  } catch (error) {
    handleErrors('Error loading profile photo:', error)
  }
}

const getAgencyLogo = async (agencyId, logoSize) => {
  try {
    return await getImageAsBase64('agencies-logos', `${agencyId}_${logoSize}.png`)
  } catch (error) {
    handleErrors('Error getting agency logo:', error)
    return ''
  }
}

const getAgencyLogoPath = async (agencyId, logoSize) => {
  try {
    return await getImageUrl('agencies-logos', `${agencyId}_${logoSize}.png`)
  } catch (error) {
    handleErrors('Error getting agency url logo:', error)
    return ''
  }
}

watch(
  () => customNavigation.value.currentPage,
  async (newVal) => {
    if (isLoggingOut.value) return
    window.scrollTo({ top: 0, behavior: 'instant' })
    if (newVal === customNavigation.value.routes.Home.name) {      
      duplicateEmployerMatch.value = false
      searchActive.value = false
      resetQuestionStore()
      resetAllEmployerStore()
      await getUserRealData()
      await getUserData()
      await getAllEmployers()
      await getEmployerList()
    } else if (newVal === customNavigation.value.routes.ListOfEmployers.name) {
      resetAllEmployerStore()
      resetQuestionStore()
    } else if (newVal.includes(customNavigation.value.navigationParams.employerId)) {
      await getEmployer(customNavigation.value.navigationParams.employerId)
      if (actualEmployer.value) {
        fillFormWithEmployerData()
      }
    }
    if (typeof window._mfq !== 'undefined') {
      // Notify to Mouseflow about route change with page title
      window._mfq.push(['newPageView', getPageTitle(newVal)])
    }
  }
)
watch([employerAmountToShow, orderBySelected], getEmployerList)


watch(refNavigate, () => {
  searchActive.value = false
})


async function getUserData() {
  loading.value = true
  const inUserResponse = await simpleGet(
    'getUserData',
    `${import.meta.env.VITE_API_BASE_URL}?_rest=User`
  )
  if (inUserResponse) {
    const user = inUserResponse.data.object || {}
    userData.value = {
      ...user,
      ...userData.value,
      employers: user.employers || [],
      totalRiskFormatted: formatter.format(
        Math.round((user.totalRisk || 0) / 100) * 100
      ),
      role: realDataUser.value.role,
      location: realDataUser.value.location,
      creationTime: realDataUser.value.creationTime,
      lastLogin: realDataUser.value.lastLogin,
      profilePhotoUrl: profilePhotoUrl.value,
      logoBase64: await getAgencyLogo(user.agencyId, user.logoSize),
      logoPath: await getAgencyLogoPath(user.agencyId, user.logoSize)
    }
  }
  const inProgressResponse = await simpleGet(
    'getUserInProgress',
    `${import.meta.env.VITE_API_BASE_URL}?_rest=Data&id=PROGRESS`
  )
  if (inProgressResponse) {
    inProgress.value = inProgressResponse.data.object || 0
  }
  loading.value = false
}

async function getEmployerList() {
  loading.value = true
  const getResponse = await simpleGet(
    'getEmployerList',
    `${import.meta.env.VITE_API_BASE_URL}?_rest=Employers&pageSize=${employerAmountToShow.value}&sortField=${orderBySelected.value.sortField}&sortDirection=${orderBySelected.value.sortDirection}`
  )
  if (getResponse) {
    employerList.value = getResponse.data.object?.data || []
    employerTotalCount.value = getResponse.data.object?.totalCount || 0
  }
  loading.value = false
}

async function getAllEmployers() {  
  loading.value = true
  const getResponse = await simpleGet(
    'getEmployerList',
    `${import.meta.env.VITE_API_BASE_URL}?_rest=Employers&pageSize=999999999999`
  )
  if (getResponse) {
    allEmployersList.value = getResponse.data.object?.data || []
  }

  loading.value = false
}

function increaseEmployerAmountToShow() {
  const newValue = employerAmountToShow.value + 10
  if (newValue > employerTotalCount.value) {
    employerAmountToShow.value = employerTotalCount.value
  } else {
    employerAmountToShow.value = newValue
  }
}

function sortEmployers(selectedOption) {
  orderBySelected.value = selectedOption
}

function toggleLoading() {
  // console.log('toggleLoading', _isOn)
  //loading.value = isOn
}

// const formatDate = (dateString) => {
//     const date = new Date(dateString);
//     return date.toLocaleDateString('es-ES', {
//         day: '2-digit',
//         month: 'short',
//         year: 'numeric'
//     });
// };

function toggleLoadingWithMessage(isOn, message) {
  loading.value = isOn
  messageForLoading.value = message
}

provide('toggleLoadingWithMessage', toggleLoadingWithMessage)

function fillFormWithEmployerData() {
  setValues({
    name: actualEmployer.value.name,
    ein: actualEmployer.value.ein,
    plan_start_date: actualPlanYear.value.plan_start_date,
    contact_name: actualEmployer.value.contact_name,
    contact_emails: actualEmployer.value.contact_emails
  })
}

function cleanFormNewUser() {
  setValues({
    name: '',
    ein: '',
    plan_start_date: '',
    contact_name: '',
    contact_emails: ''
  })
  setTouched({
    name: false,
    ein: false,
    plan_start_date: false,
    contact_name: false,
    contact_emails: false
  })
  //document.querySelectorAll(".modal-backdrop").forEach(el => el.remove());
}

async function prepareForCreateEmployer() {
  const fechaActual = new Date()
  let newEmployer = await createEmployer({
    name: name.value,
    ein: ein.value,
    agency_id: userData.value.agencyId,
    plan_start_date: plan_start_date.value,
    contact_name: contact_name.value,
    contact_emails: contact_emails.value,
    creation_date: formatDateToSQLInChicagoTime(fechaActual)
  })

  if (newEmployer) {
    isNewEmployerAdded.value.state = true
    isNewEmployerAdded.value.id = newEmployer.id
  }
  await getEmployerList()
  await getAllEmployers()
}

async function prepareForUpdateEmployer() {
  let updatedEmployer = await updateEmployer(actualEmployer.value.id, {
    id: actualEmployer.value.id,
    name: name.value,
    ein: ein.value,
    agency_id: userData.value.agencyId,
    complianceYear: plan_start_date.value,
    complianceYearId: actualPlanYear.value.id,
    contact_name: contact_name.value,
    contact_emails: contact_emails.value
  })

  if (updatedEmployer) {
    await getEmployerList()
    await getUserData()
    await getAllEmployers()
   
    const EMPLOYER_INFORMATION_UPDATED = 8
    createLog({
      eventId: EMPLOYER_INFORMATION_UPDATED,
      logName: 'user_activity_log',
      employerId: actualEmployer.value.id,
      agencyId: actualEmployer.value.agency_id
    }).catch((err) => handleErrors(err))
  }

 

  //document.querySelectorAll(".modal-backdrop").forEach(el => el.remove());
}

// Function to recalculate total compliance risk for all employers
// async function processAllEmployers() {
//   toggleLoadingWithMessage(true, 'Processing all employers...')
//   // define the employers ids to process
//   // for (let i = 1; i <= 192; i++) {
//   for (let i of [200, 174]) {
//     try {
//       customNavigation.value.navigate({
//         newPage: customNavigation.value.routes.DetailEmployer.name,
//         subPage: customNavigation.value.routes.DetailEmployer.children[0].name,
//         params: { employerId: i }
//       })
//       // Wait for the page to load
//       console.log(`Processing employer ${i}`)
//       await new Promise(resolve => setTimeout(resolve, 1800))
//       const questionStore = useQuestionsStore()
//       const { isAssessmentComplete, loadQuestionsAndAnswers } = questionStore
//       await loadQuestionsAndAnswers()
//       if (isAssessmentComplete()) {
//         console.log(`Employer ${i} is already completed`)
//         const { saveScore } = ScoreCalculationsService()
//         await saveScore(i)
//       }
//       customNavigation.value.navigate({
//         newPage: customNavigation.value.routes.Home.name
//       })
//       await new Promise(resolve => setTimeout(resolve, 700))
//     } catch (error) {
//       console.error(`Error processing employer ${i}:`, error)
//     }
//   }
//   toggleLoadingWithMessage(false)
// }


const handleSearch = ({ results }) => {
  searchActive.value = true
  searchResults.value = results  
}

const handleClear = () => {
  searchActive.value = false
}

const clearTableSearch = () => {
  searchActive.value = false
  if (searchDropdownRef.value) {
    searchDropdownRef.value.clearSearch()
  }
}

function isNameTaken() {
  checkDuplicateEmployer.value = false
  const existingEmployer = allEmployersList.value.find(employer => 
    employer.name.trim().toLowerCase() === name.value.trim().toLowerCase()
  )
  if (existingEmployer) {
    duplicateEmployerMatch.value = existingEmployer
  }  
  checkDuplicateEmployer.value = !!existingEmployer
}

const goToExistingEmployer = () => {  
  event.preventDefault()
  customNavigation.value.navigate({
            newPage: customNavigation.value.routes.DetailEmployer.name,
            subPage: customNavigation.value.routes.DetailEmployer.children[0].name,
            params: { employerId: duplicateEmployerMatch.value.id }
          })
}


// Navbar 
const navItems = computed(() => [
  {
    id: customNavigation.value.routes.Home.name, 
    label: 'Home',
    target: customNavigation.value.routes.Home.name
  },
  {
    id: customNavigation.value.routes.ListOfEmployers.name, 
    label: 'Employers',
    target: customNavigation.value.routes.ListOfEmployers.name
  },
  {
    id: customNavigation.value.routes.helpAndResources.name,
    label: 'Help & Resources',
    target: customNavigation.value.routes.helpAndResources.name
  }
])

const activeNavItem = computed(() => {
  const currentPage = customNavigation.value.currentPage
  if (currentPage === customNavigation.value.routes.Home.name) {
    return 'home'
  }
  if (currentPage === customNavigation.value.routes.ListOfEmployers.name || 
      currentPage.includes(customNavigation.value.routes.DetailEmployer.name.slice(0, 14))) {        
    return 'listOfEmployers'
  }
  if (currentPage === customNavigation.value.routes.helpAndResources.name ||
      currentPage === customNavigation.value.routes.frequentlyQuestions.name ||
      currentPage === customNavigation.value.routes.glossaryTerms.name) {
    return 'helpAndResources'
  }
  return ''
})

const profileItems = [
  { 
    id: 'profile', 
    label: 'Profile', 
    target: 'profile',
    type: 'dropdown'
  },
  { 
    id: 'help', 
    label: 'Help', 
    target: 'help',
    type: 'dropdown'
  },
  { 
    id: 'logout', 
    label: 'Log Out', 
    target: 'logout',
    type: 'dropdown'
  }
]

const handleNavClick = (item) => {
  customNavigation.value.navigate({
    newPage: item.target
  })
}

const handleLogoClick = () => {
  customNavigation.value.navigate({
    newPage: customNavigation.value.routes.Home.name
  })
}

const handleProfileClick = (item) => {
  if (item.id === 'profile') {
    customNavigation.value.navigate({
      newPage: customNavigation.value.routes.memberProfile.name
    })
  } else if (item.id === 'help') {
    customNavigation.value.navigate({
      newPage: customNavigation.value.routes.helpAndResources.name
    })
  }
}
</script>

<template>
  <div class="bg-white loading-overlay" v-if="loading"
    :style="`--bs-bg-opacity: ${messageForLoading ? '0.95' : '0.5'}`">
    <div v-if="messageForLoading">
      <div class="text-center header-24 mb-2">
        <med>{{ messageForLoading.title }}</med>
      </div>
      <div class="text-center text-16 white-space-pre-wrap">{{ messageForLoading.body }}</div>
    </div>
    <div v-else class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>

  <NavBar
  :logoSrc="brandLogo"
  logoAlt="bcs logo"
  :activePage="activeNavItem"
  :userData="userData"
  navMode="router"
  navStyle="app"
  :navItems="navItems"
  :actionItems="profileItems"
  @nav-click="handleNavClick"
  @action-click="handleProfileClick"
  @logo-click="handleLogoClick"
  @logout="logout"
/>

  <!-- to move this section to an external component!-->
  <div v-if="customNavigation.currentPage === customNavigation.routes.Home.name">
    <div class="container-fluid main-container">
      <div class="row d-flex justify-content-center">
        <div class="col-12 text-left ps-3 mt-3.5 mb-4 text-28 text-secondary">
          <med>Hello, {{ userData.fullname }}</med>
          <!-- <button class="btn btn-primary ms-3" @click="processAllEmployers">Process All Employers</button> -->
        </div>
      </div>
      <div class="row d-flex justify-content-center ps-0.5 pe-2.5">

        <div class="col-12 col-md-custom-4-5 pe-1.5">
          <div class="card">
            <div class="card-header px-4 py-2.5 bg-light d-flex justify-content-between align-items-center">
              <h5 class="card-title add-teamates-link header-22">
                <strong>My Profile</strong>
              </h5>
              <a @click="
                customNavigation.navigate({
                  newPage: customNavigation.routes.memberProfile.name
                })" class="view-full-profile-link hand-cursor text-decoration-none text-14 text-primary">
                <strong>
                  Go to Full Profile
                  <i class="bi bi-chevron-right ms-2"></i>
                </strong>
              </a>
            </div>
            <div class="card-body pt-4 mt-2 px-4 internal-round-border">
              <div class="row">
                <div class="col-4 col-md-12 col-lg-4">
                  <div class="text-center mb-2">
                    <img :src="profilePhoto" alt="profile img" :class="[
                      'profile-img img-fluid',
                      (userData.temporaryPhotoURL && userData.temporaryPhotoURL !== '/user_silhouette.png') || userData.profilePhotoUrl ? 'rounded-circle' : ''
                    ]" v-if="profilePhoto !== null" />
                    <img src="/user_silhouette.png" alt="User Silhouette" class="img-fluid user-silhouette" v-else />
                  </div>
                </div>
                <div style="margin-top: -0.25rem" class="col-8 col-md-12 col-lg-8">
                  <div class="row">
                    <div class="col-5 col-xxl-4">
                      <p class="my-profile-label text-14">
                        <strong>Name</strong>
                      </p>
                    </div>
                    <div class="col-7 ps-0 col-xxl-8">
                      <p class="mb-0 text-14">
                        {{ userData.fullname }}
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-5 col-xxl-4">
                      <p class="my-profile-label text-14">
                        <strong>Title</strong>
                      </p>
                    </div>
                    <div class="col-7 ps-0 col-xxl-8">
                      <p class="mb-0 text-14">
                        {{ userData.role || 'N/a' }}
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-5 col-xxl-4">
                      <p class="my-profile-label text-14">
                        <strong>Company</strong>
                      </p>
                    </div>
                    <div class="col-7 ps-0 col-xxl-8">
                      <p class="mb-0 text-14">
                        {{ userData.companyName }}
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-5 col-xxl-4">
                      <p class="mb-2 text-14">
                        <strong>Email</strong>
                      </p>
                    </div>
                    <div class="col-7 ps-0 col-xxl-8">
                      <p class="mb-0 text-14">
                        {{ userData.email }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-custom-7-5 pe-0 ps-3">
          <div class="card">
            <div class="card-header ps-4 pt-2 pb-2.5 bg-light">
              <h5 class="card-title add-teamates-link header-22 text-secondary">
                <strong>My Stats</strong>
              </h5>
            </div>
            <div class="card-body text-center ps-4.5 pe-4 pb-3 pt-3.5">
              <div class="row">
                <div class="col-sm">
                  <div class="d-flex justify-content-center align-items-end">
                    <h1
                      class="stat-data-pill text-primary my-auto mb-2.5 pt-1 px-3.5 bg-light rounded-pill header-40 text-dark">
                      <strong>{{ inProgress }}</strong>
                    </h1>
                  </div>
                  <h5 class="mb-0 text-14">
                    <strong>Employers in Progress</strong>
                  </h5>
                  <p class="text-12 mt-2 mb-0">
                    <BcsDynamicText :key="inProgress" text="employersInProgressText"
                      :variables="{ employersInProgress: inProgress }" />
                  </p>
                </div>
                <div class="col-sm">
                  <div class="d-flex justify-content-center align-items-end">
                    <h1
                      class="stat-data-pill text-primary my-auto mb-2.5 pt-1 px-3.5 bg-light rounded-pill header-40 text-dark">
                      <strong>{{ userData.employerCount || 0 }}</strong>
                    </h1>
                  </div>
                  <h5 class="mb-0 text-14">
                    <strong>Total Employers</strong>
                  </h5>
                  <p class="text-12 mt-2 mb-0">
                    <BcsDynamicText :key="userData.employerCount" text="totalEmployersText"
                      :variables="{ totalEmployers: userData.employerCount || 0 }" />
                  </p>
                </div>
                <div class="col-sm">
                  <div class="d-flex justify-content-center align-items-end">
                    <h1
                      class="stat-data-pill text-primary my-auto mb-2.5 px-3.5 pt-1 bg-light rounded-pill header-40 text-dark">
                      <strong>{{ userData.totalRiskFormatted || 0 }}</strong>
                    </h1>
                  </div>
                  <h5 class="mb-0 text-14">
                    <strong>Total Compliance Risk Amount</strong>
                  </h5>
                  <p class="text-12 mt-2 mb-0">
                    <BcsDynamicText v-if="userData.totalRisk !== 0" :key="userData.totalRiskFormatted"
                      text="totalComplianceRiskIdentifiedText" :variables="{
                        totalComplianceRiskIdentifiedValue: userData.totalRiskFormatted,
                        completeAssessmentEmpCount: userData.completeAssessmentEmpCount
                      }" />
                    <span v-else>
                      Start identifying the total compliance risk of your book by completing a
                      Compliance Assessment on one of your employer groups today
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row d-flex justify-content-center mt-20 ps-1.5 pe-2.5">
        <div class="col-md-12 pe-0 ps-2">
          <div class="card">
            <div
              class="card-header card-header-activity bg-light ps-4 pe-4 d-flex justify-content-between align-items-center">
              <h5 class="card-title add-teamates-link header-22 text-secondary">
                <strong>Latest Activity</strong>
              </h5>
              <a class="view-full-profile-link hand-cursor text-decoration-none text-14 text-primary" @click.prevent="
                customNavigation.navigate({
                  newPage: customNavigation.routes.ListOfEmployers.name
                })
                " href="#">
                <strong>Go to All Employers</strong>
                <i class="bi bi-chevron-right ms-2"></i>
              </a>
            </div>
            <div class="card-body pt-0 px-0 pb-0">
              <div class="table-responsive">
                <table class="table mb-1 border-0" v-if="userData.employers?.length > 0">
                  <thead>
                    <tr>
                      <th scope="col" class="text-16 ps-4">
                        <strong>Employer Name</strong>
                      </th>
                      <th scope="col" class="text-16">
                        <strong>Latest Activity</strong>
                      </th>
                      <th scope="col" class="text-16">
                        <strong>Activity Date</strong>
                      </th>
                      <th scope="col" class="text-16">
                        <strong>Team Member</strong>
                      </th>
                      <th scope="col" class="text-16">
                        <strong>Title</strong>
                      </th>
                      <th scope="col" class="text-16"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="" v-for="(employer, i) in userData.employers" :key="i">
                      <td class="border border-0 ps-4 text-truncate" style="max-width: 200px;">
                        <a @click.prevent="
                          customNavigation.navigate({
                            newPage: customNavigation.routes.DetailEmployer.name,
                            subPage: customNavigation.routes.DetailEmployer.children[0].name,
                            params: { employerId: employer.id }
                          })
                          " href="#" class="text-primary text-decoration-none text-14 text-primary hand-cursor">
                          <strong>{{ employer.name }}</strong>
                        </a>
                      </td>
                      <td class="border border-0 py-0 text-truncate" style="max-width: 200px;">
                        <span
                          class="d-inline-block duedate-pill rounded-pill bg-light px-2 text-14 text-secondary text-decoration-none">
                          <strong>
                            {{ employer.log_entry?.eventDescription || 'N/a' }}
                          </strong>
                        </span>
                      </td>
                      <td class="border border-0 text-14 text-secondary py-0">
                        {{ employer.log_entry?.activityDate || 'N/a' }}
                      </td>
                      <td class="border border-0 py-0 text-truncate" style="max-width: 150px;">
                        <a data-bs-toggle="modal" data-bs-target="#pendingFunctionModal"
                          class="text-primary text-decoration-none text-14 text-primary hand-cursor">
                          <strong>
                            {{ employer.log_entry?.teamMemberName || 'N/a' }}
                          </strong>
                        </a>
                      </td>
                      <td class="border border-0 text-14 text-secondary py-0 text-truncate" style="max-width: 150px;">
                        {{ 'N/a' }}
                      </td>
                      <td class="border border-0 py-0 pe-4 text-end">
                        <button @click="
                          customNavigation.navigate({
                            newPage: customNavigation.routes.DetailEmployer.name,
                            subPage: customNavigation.routes.DetailEmployer.children[0].name,
                            params: { employerId: employer.id }
                          })
                          " class="hand-cursor btn btn-primary px-2 py-0.5 gotoEmployerBtn">
                          <i class="bi bi-chevron-right text-white"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table class="table mb-1" style="min-height: 200px" v-else>
                  <tbody>
                    <tr v-if="!loading">
                      <div class="text-center">
                        <div>
                          <i class="bi bi-plus-circle fs-1 mt-4 opacity-25"></i>
                        </div>

                        <div class="text-14 text-secondary">Add in an empty state message here</div>
                        <div>
                          <button class="btn btn-primary px-2 py-1 mt-3 mb-4 fw-bold" data-bs-toggle="modal"
                            data-bs-target="#createUpdateEmployerModal">
                            <i class="bi bi-plus-circle-fill"></i>
                            Add Employer
                          </button>
                        </div>
                      </div>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Faqs section="general"></Faqs>
    </div>
    <Footer class="main-container"></Footer>
  </div>

  <div v-else-if="customNavigation.currentPage === customNavigation.routes.ListOfEmployers.name">
    <div class="container-fluid main-container">
      <div class="row d-flex justify-content-center">
        <div class="col-12 text-left my-3 text-28">
          <med class="text-secondary">All Employers Dashboard</med>
        </div>
      </div>
      <div class="row d-flex justify-content-center mt-0">
        <div class="col-md-12">
          <div class="card border-0">
            <div class="card-body pt-0 px-0" v-if="userData.employers">
              <div class="d-flex justify-content-between py-3">
                <button type="button" class="btn btn-primary d-flex align-items-center" data-bs-toggle="modal"
                  data-bs-target="#createUpdateEmployerModal">
                  <i
                    class="bi bi-plus bg-white text-primary rounded-circle d-flex justify-content-center align-items-center fs-5"></i>
                  &nbsp; Add New Employer
                </button>

                <div class="d-flex align-items-center">
                  <div style="padding-right: 26px;">
                    <BcsSearchDropdown ref="searchDropdownRef" @clear="handleClear" @search="handleSearch"
                      :search-fields="['name']" :showResults="false" :placeholder="'Search Employer by Name'"
                      :items="allEmployersList" :search-button-text="''" />
                  </div>
                  <div :class="{'text-gray-500': searchActive}" class="mb-0 me-3">Sort by</div>
                  <div class="dropdown" :class="{'dropdown-btn-disabled': searchActive}">
                    <button :disabled="searchActive" type="button" class="btn btn-outline-secondary btn-sort"
                      id="dropdownMenuSort" data-bs-toggle="dropdown" aria-expanded="false">
                      <span class="text-16 text-primary me-2">{{ orderBySelected.label }}</span>
                      <i class="bi bi-caret-down-fill ms-5 text-primary"></i>
                    </button>
                    <ul class="dropdown-menu dropdown-menu-end w-100" aria-labelledby="dropdownMenuSort">
                      <li v-for="opcion in orderOptions" :key="opcion.value" class="dropdown-item hand-cursor" :class="{
                        'bg-primary text-white': orderBySelected.value === opcion.value
                      }" @click="sortEmployers(opcion)" @keydown.enter="$event.target.click()" tabindex="0">
                        <span>
                          {{ opcion.label }}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="border mb-3 rounded rounded-1">
                <table class="table mb-1">
                  <thead>
                    <tr>
                      <th scope="col" class="py-3 rounded rounded-1 border-end-0 text-16 ps-4 text-secondary">
                        <strong>Employer Name</strong>
                      </th>
                      <th scope="col" class="py-3 border-start-0 border-end-0 text-16 text-secondary">
                        <strong>Latest Activity</strong>
                      </th>
                      <th scope="col" class="py-3 border-start-0 border-end-0 text-16 text-secondary">
                        <strong>Activity Date</strong>
                      </th>
                      <th scope="col" class="py-3 border-start-0 border-end-0 text-16 text-secondary">
                        <strong>Upcoming Due Dates</strong>
                      </th>
                      <th scope="col" class="py-3 rounded rounded-1 border-start-0"></th>
                    </tr>
                  </thead>
                  <tbody v-if="!searchActive">
                    <tr v-for="(employer, i) in employerList" :key="i"
                      :class="i == employerList.length - 1 ? 'border border-0' : ''">
                      <td :class="i == employerList.length - 1 ? 'border border-0' : ''" class="py-1 border-end-0 ps-4">
                        <a @click.prevent="
                          customNavigation.navigate({
                            newPage: customNavigation.routes.DetailEmployer.name,
                            subPage: customNavigation.routes.DetailEmployer.children[0].name,
                            params: { employerId: employer.id }
                          })
                          " href="#" class="text-decoration-none text-14 hand-cursor">
                          <strong>{{ employer.name }}</strong>
                        </a>
                      </td>
                      <td :class="i == employerList.length - 1 ? 'border border-0' : ''"
                        class="py-1 border-start-0 border-end-0">
                        <span class="badge rounded-pill text-bg-light text-14 text-decoration-none text-secondary">
                          <strong>{{ employer.log_entry?.eventDescription || 'N/a' }}</strong>
                        </span>
                      </td>
                      <td :class="i == employerList.length - 1 ? 'border border-0' : ''"
                        class="py-1 border-start-0 border-end-0 text-14 text-secondary">
                        {{ employer.log_entry?.activityDate || 'N/a' }}
                      </td>
                      <td :class="i == employerList.length - 1 ? 'border border-0' : ''"
                        class="py-1 border-start-0 border-end-0">
                        <a data-bs-toggle="modal" data-bs-target="#pendingFunctionModal"
                          class="hand-cursor text-decoration-none text-14">
                          <strong>N/a</strong>
                        </a>
                      </td>
                      <td :class="i == employerList.length - 1 ? 'border border-0' : ''" @click="
                        customNavigation.navigate({
                          newPage: customNavigation.routes.DetailEmployer.name,
                          subPage: customNavigation.routes.DetailEmployer.children[0].name,
                          params: { employerId: employer.id }
                        })
                        " class="py-1 border-start-0">
                        <button class="btn btn-primary px-2 py-1">
                          <i class="bi bi-chevron-right text-white"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr v-for="(employer, i) in searchResults" :key="i"
                      :class="i == searchResults.length - 1 ? 'border border-0' : ''">
                      <td :class="i == searchResults.length - 1 ? 'border border-0' : ''"
                        class="py-1 border-end-0 ps-4">
                        <a @click.prevent="
                          customNavigation.navigate({
                            newPage: customNavigation.routes.DetailEmployer.name,
                            subPage: customNavigation.routes.DetailEmployer.children[0].name,
                            params: { employerId: employer.id }
                          }),
                          searchActive = false
                          " href="#" class="text-decoration-none text-14 hand-cursor">
                          <strong>{{ employer.name }}</strong>
                        </a>
                      </td>
                      <td :class="i == searchResults.length - 1 ? 'border border-0' : ''"
                        class="py-1 border-start-0 border-end-0">
                        <span class="badge rounded-pill text-bg-light text-14 text-decoration-none text-secondary">
                          <strong>{{ employer.log_entry?.eventDescription || 'N/a' }}</strong>
                        </span>
                      </td>
                      <td :class="i == searchResults.length - 1 ? 'border border-0' : ''"
                        class="py-1 border-start-0 border-end-0 text-14 text-secondary">
                        {{ employer.log_entry?.activityDate || 'N/a' }}
                      </td>
                      <td :class="i == searchResults.length - 1 ? 'border border-0' : ''"
                        class="py-1 border-start-0 border-end-0">
                        <a data-bs-toggle="modal" data-bs-target="#pendingFunctionModal"
                          class="hand-cursor text-decoration-none text-14">
                          <strong>N/a</strong>
                        </a>
                      </td>
                      <td :class="i == searchResults.length - 1 ? 'border border-0' : ''" @click="
                        customNavigation.navigate({
                          newPage: customNavigation.routes.DetailEmployer.name,
                          subPage: customNavigation.routes.DetailEmployer.children[0].name,
                          params: { employerId: employer.id }
                        }),
                        searchActive = false
                        " class="py-1 border-start-0">
                        <button class="btn btn-primary px-2 py-1">
                          <i class="bi bi-chevron-right text-white"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>

                </table>

                <div v-if="searchActive && searchResults.length==0" style="margin-top: 64px; margin-bottom: 69px;"
                  class="text-14 d-flex flex-column align-items-center justify-content-center">
                  <p>
                    <BcsDynamicText text="searchNoResultsMessage" />
                  </p>
                  <button @click="clearTableSearch" class="btn btn-primary">
                    <i class="bi bi-x-circle-fill"></i>
                    Clear Search
                  </button>
                </div>

              </div>

              <div v-if="searchActive && searchResults.length>0" style="margin-top: 30px;"
                class="text-14 d-flex flex-column align-items-center justify-content-center">
                <p>
                  <BcsDynamicText text="searchNoResultsMessage" />
                </p>
                <p @click="clearTableSearch" style="cursor: pointer;" class="text-primary fw-bold"> <i
                    style="padding-right: 8px;" class="bi bi-x-circle-fill"></i> Clear Table Search Results </p>
              </div>

              <p v-if="!searchActive" class="text-12" style="color: var(--bs-gray-700)">
                You’re viewing {{ employerAmountToShow > employerTotalCount ? employerTotalCount : employerAmountToShow
                }} of {{ employerTotalCount }} employers
              </p>
              <button
                v-if="(employerAmountToShow !== employerTotalCount && employerAmountToShow < employerTotalCount) && !searchActive"
                type="button" class="btn btn-info text-16" @click="increaseEmployerAmountToShow">
                <med>Load More Employers</med>
                <i class="bi bi-chevron-double-down ms-2"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <Faqs :loading="loading" section="employeDashboard"></Faqs>
    </div>
    <Footer class="main-container"></Footer>
  </div>

  <ExistingEmployerDashboard v-else-if="
    customNavigation.currentPage.includes(
      customNavigation.getDynamicRoute(customNavigation.routes.DetailEmployer.name, {
        employerId: customNavigation.navigationParams.employerId
      })
    )
  " @toggleLoading="(isOn) => toggleLoading(isOn)" :employerId="customNavigation.navigationParams.employerId" />

  <BcsFaqs v-else-if="customNavigation.currentPage === customNavigation.routes.frequentlyQuestions.name"
    @toggleLoading="(isOn) => toggleLoading(isOn)" />

  <BcsGlossary v-else-if="customNavigation.currentPage === customNavigation.routes.glossaryTerms.name"
    @toggleLoading="(isOn) => toggleLoading(isOn)" />


  <HelpAndResources v-else-if="customNavigation.currentPage === customNavigation.routes.helpAndResources.name"
    @toggleLoading="(isOn) => toggleLoading(isOn)" />

  <TermsAndConditions v-else-if="customNavigation.currentPage === customNavigation.routes.termsAndConditions.name"
    @toggleLoading="(isOn) => toggleLoading(isOn)" />

  <BcsMemberProfile v-else-if="customNavigation.currentPage === customNavigation.routes.memberProfile.name"
    @toggleLoading="(isOn) => toggleLoading(isOn)" />

  <!--  -->

  <Offcanvas />
  <CenterDefinitionModal />
  <BcsContactModal />

  <!-- createUpdateEmployerModal -->
  <div class="modal" id="createUpdateEmployerModal" ref="createUpdateEmployerModal" tabindex="-1"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header px-4 d-flex flex-column bg-gray-200">
          <div class="d-flex w-100 justify-content-between">
            <span class="modal-title header-20" id="answerModalLabel">
              <strong>{{ actualEmployer ? 'Edit Employer' : 'Add New Employer' }}</strong>
            </span>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <p class="me-5 mb-0 text-14 text-gray-700 mt-1">
            <BcsDynamicText :text="actualEmployer ? 'editEmployerModalDescriptionText' : 'addEmployerDescriptionText'
              " />
          </p>
        </div>
        <div class="modal-body">
          <form class="px-2">
            <div class="mb-3">
              <label for="recipient-name" class="col-form-label text-14">
                <strong>Employer name</strong>
              </label>
              <input  @blur="isNameTaken()"  type="text" class="form-control text-16"
                :class="{ 'is-invalid': !isFieldValid('name') && isFieldTouched('name') }" minlength="4" v-model="name"
                v-bind="nameAttrs" placeholder="e.g., XYZ Company" />

              <div v-if="checkDuplicateEmployer && isFieldTouched('name')" class="duplicate-name-container mt-1 small">
                <div class="d-flex duplicate-name-container__message">
                  <i class="bi bi-info-circle-fill"></i>
                   <div><BcsDynamicText text="duplicateEmployerText" /></div>
                </div>

                <div class="duplicate-name-container__buttons ">
                
                  <div class="d-flex justify-content-between">
                    <button data-bs-dismiss="modal" class="btn btn-info text-14" @click="goToExistingEmployer()">
                    Go to Existing Employer Dashboard
                    <i class="bi bi-chevron-right"></i>
                  </button>
                  <button v-if="false" class="btn btn-primary text-14">
                    Create Renewal 
                    <i class="bi bi-plus-circle"></i>
                  </button>
                  </div>

                  <div @click="checkDuplicateEmployer = false"  class="text-primary fw-bold continue-adding-button">
                    Continue Adding a New Employer
                  </div>
                  
                </div>
              </div>

            </div>
            <div class="d-flex" style="gap: 28px;">
              <div class="mb-3 " style="width: 60%;">
                <label for="message-text" class="col-form-label text-14">
                  <strong>Employer EIN</strong>
                </label>

                <input type="text" class="form-control text-16"
                  :class="{ 'is-invalid': !isFieldValid('ein') && isFieldTouched('ein') }" v-model="ein"
                  v-bind="einAttrs" v-maska="rawMaskVal" data-maska="##-#######" placeholder="e.g., 12-3456789" />
              </div>
              <div class="mb-3 ">
                <label for="message-text" class="col-form-label text-14">
                  <strong>Plan Start Date</strong>
                </label>
                <input type="text" class="form-control text-16" :class="{
                  'is-invalid':
                    !isFieldValid('plan_start_date') && isFieldTouched('plan_start_date')
                }" v-model="plan_start_date" v-bind="planStartDateAttrs" v-maska="rawMaskVal" data-maska="##/##/####"
                  placeholder="e.g., MM/DD/YYYY" />
              </div>
            </div>
            <div class="mb-3">
              <label for="recipient-name" class="col-form-label text-14">
                <strong>Primary Contact Name</strong>
              </label>
              <input type="text" class="form-control text-16" :class="{
                'is-invalid': !isFieldValid('contact_name') && isFieldTouched('contact_name')
              }" v-model="contact_name" v-bind="primaryContactNameAttrs" placeholder="e.g., Jane Doe" />
            </div>
            <div class="mb-3">
              <label for="recipient-name" class="col-form-label text-14">
                <strong>Primary Contact Email(s)</strong>
              </label>
              <p class="text-14 text-gray-700 mb-1">
                Enter up to two emails. Separate with a comma.
              </p>
              <input type="text" class="form-control text-16" :class="{
                'is-invalid': !isFieldValid('contact_emails') && isFieldTouched('contact_emails')
              }" v-model="contact_emails" v-bind="primaryContactEmailAttrs"
                placeholder="e.g., janedoe@xyz.com, johnsmith@xyz.com" />
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" @click="cleanFormNewUser" class="btn text-primary bg-info" data-bs-dismiss="modal">
            Cancel
          </button>
          <button type="button" :disabled="hasErrors || hasEmptyValues || checkDuplicateEmployer" class="btn btn-primary"
            @click="actualEmployer ? prepareForUpdateEmployer() : prepareForCreateEmployer()" data-bs-dismiss="modal">
            {{ actualEmployer ? 'Save Employer Details' : 'Add Employer' }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- detailEmployerModal -->
  <div class="modal" id="detailEmployerModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" v-if="actualEmployer">
      <div class="modal-content">
        <div class="modal-header px-4 d-flex flex-column bg-gray-200">
          <div class="d-flex w-100 justify-content-between">
            <span class="modal-title header-20" id="answerModalLabel">
              <strong>Employer Details</strong>
            </span>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <p class="me-5 mb-0 text-14 text-gray-700 mt-1">
            <BcsDynamicText text="detailEmployerModalDescriptionText" />
          </p>
        </div>
        <div class="modal-body">
          <div class="row mx-3">
            <div class="col-12">
              <div class="row">
                <div class="col-sm-5 pe-0">
                  <p class="mb-2 text-truncate text-14">
                    <strong>Employer Name</strong>
                  </p>
                </div>
                <div class="col-sm-6 ps-0">
                  <p class="mb-0 text-truncate text-14">
                    {{ actualEmployer.name }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-5 pe-0">
                  <p class="mb-2 text-truncate text-14">
                    <strong>EIN</strong>
                  </p>
                </div>
                <div class="col-sm-6 ps-0">
                  <p class="mb-0 text-truncate text-14">
                    {{ actualEmployer.ein }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-5 pe-0">
                  <p class="mb-2 text-truncate text-14">
                    <strong>Plan Year Start</strong>
                  </p>
                </div>
                <div class="col-sm-6 ps-0">
                  <p class="mb-0 text-truncate text-14">
                    {{ actualPlanYear?.plan_start_date }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-5 pe-0">
                  <p class="mb-2 text-truncate text-14">
                    <strong>Primary Contact</strong>
                  </p>
                </div>
                <div class="col-sm-6 ps-0">
                  <p class="mb-0 text-truncate text-14">
                    {{ actualEmployer.contact_name }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-5 pe-0">
                  <p class="mb-2 text-truncate text-14">
                    <strong>Employer Emails</strong>
                  </p>
                </div>
                <div class="col-sm-6 text-14 ps-0">
                  <p class="mb-0 text-truncate add-teamates-link">
                    {{ actualEmployer.contact_emails }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" @click="cleanEmployerDetail" class="btn text-primary bg-info" data-bs-dismiss="modal">
            Close
          </button>
          <button type="button" class="btn btn-primary" data-bs-toggle="modal"
            data-bs-target="#createUpdateEmployerModal">
            Edit Employer Details
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- employer created  -->
  <div v-if="isNewEmployerAdded.state" class="col-12 overlay-white">
    <div class="card-new-employer mt-5 mx-auto">
      <div class="d-flex justify-content-end">
        <button type="button" class="btn-close" @click="isNewEmployerAdded.state = false" aria-label="Close"></button>
      </div>
      <div class="card-body text-center d-flex flex-column justify-content-center align-items-center">
        <p class="card-text text-14 text-gray-700">
          <i class="bi bi-check-circle"></i>
        </p>

        <strong>
          <h4>New Employer Added!</h4>
        </strong>
        <p class="text-12">
          Now that you've added {{ name || '' }}, completing the Intake Questionnaire will customize
          and optimize this employer's Assessment, Planning, and Calendar results.
        </p>

        <button @click="
          customNavigation.navigate({
            newPage: customNavigation.routes.DetailEmployer.name,
            subPage: customNavigation.routes.DetailEmployer.children[1].name,
            params: { employerId: isNewEmployerAdded.id }
          }),
          (isNewEmployerAdded.state = false)
          " class="btn btn-primary">
          <i class="bi bi-list-check"></i>
          &nbsp; Complete Intake Questionnaire
        </button>

        <strong @click="
          customNavigation.navigate({
            newPage: customNavigation.routes.DetailEmployer.name,
            subPage: customNavigation.routes.DetailEmployer.children[0].name,
            params: { employerId: isNewEmployerAdded.id }
          }),
          (isNewEmployerAdded.state = false)
          " class="complete-intake-later">
          I’ll complete the Intake Questionnaire later.
        </strong>
      </div>
    </div>
  </div>

  <!-- pendingFunctionModal -->
  <div class="modal fade" id="pendingFunctionModal" tabindex="-1" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Pending functionalty</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <h2>Stay tuned for updates and new version releases</h2>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
body.body--light {
  color: black;
}

.q-parallax__media>img {
  min-width: 50% !important;
}

.card {
  height: 100%;
}

.hand-cursor {
  cursor: pointer;
}

.z-index-1021 {
  z-index: 1021;
}

.opacity-50 {
  opacity: 50%;
}

.opacity-25 {
  opacity: 25%;
}

.bg-gray-200 {
  background-color: var(--bs-gray-200);
}

html {
  scroll-behavior: smooth;
}

.row-adjustement {
  width: 100vw;
}

:host {
  table {
    margin-bottom: 0;
  }
}

.table-responsive>.table {
  margin-bottom: 0;
  border: 0;
}

.card-header-activity {
  height: 51px;
}

tr {
  border-bottom: 1px solid #c5cdd4;
}

tbody tr:last-child {
  border: none;
}

.loading-overlay {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  height: 100vh;
  width: 100vw;
  --bs-bg-opacity: 0.95;
  // padding-top: 40vh;
  // padding-left: 49vw;
}

.mt-20 {
  margin-top: 2rem;
}

.card-body {
  border-radius: var(--bs-border-radius) !important;
}

.loading-overlay {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  height: 100vh;
  width: 100vw;
  --bs-bg-opacity: 0.95;
  // padding-top: 40vh;
  // padding-left: 49vw;
}

.mt-20 {
  margin-top: 20px;
}

.card-body {
  border-radius: var(--bs-border-radius) !important;
}

.padding-logo {
  padding-right: 10%;
}

.h-61 {
  height: 61px !important;
}

.padding_icons {
  padding-top: 3px;
  padding-bottom: 3px;
}

.company_logo {
  display: flex;
  justify-content: center;
}

.btn-sort:hover {
  border-color: var(--bs-link-color-rgb);
  color: var(--bs-link-color);
  background-color: transparent;
}

.btn-sort:focus-visible {
  border-color: var(--bs-link-color-rgb);
  color: var(--bs-link-color);
  background-color: transparent;
}

.logo {
  height: 20px;
  // margin-top: 1.4vh;
}

.card {
  height: 100%;
}

.add-teamates-link {
  display: contents;
}

.n-margin-8 {
  margin-right: -30px;
}

.btn-sort:hover {
  border-color: var(--bs-link-color-rgb);
  color: var(--bs-link-color);
  background-color: transparent;
}

.footer-color {
  background-color: #e9ecef;
}

.bi-check-circle {
  font-size: 86px;
  color: #198754;
}

.duedate-pill {
  padding: 6px;
}

.hand-cursor {
  cursor: pointer;
}

.card-body {
  border-radius: var(--bs-card-border-radius);
}

.stat-data-pill {
  height: 60px;
}

.profile-img {
  width: 118px;
}

.dropdown-btn-disabled{

  button{
    border-radius: 4px!important;
    border: 1px solid var(--Gray-400, #CED4DA)!important;
    background: var(--Gray-200, #E9ECEF)!important;

    span{
      color: var(--Gray-500, #ADB5BD)!important;
    }

    i{
      color: var(--Gray-500, #ADB5BD)!important;
    }
  }
  
}

.card-new-employer {
  padding: 18px;
  border: 1px solid #c5cdd4;
  border-radius: 4px;
  background-color: #fff;
  width: 454px;
  height: 375px;
  box-shadow: 4px 4px 8px 0px #0000001a;

  .complete-intake-later {
    color: var(--bs-primary);
    margin-top: 26px;
    font-size: 14px;
    cursor: pointer;
  }

  i {
    margin-top: 43px;
  }

  button {
    margin-top: 6px;

    .stat-data-pill {
      height: 69px;
    }

    h4 {
      font-weight: 700;
      font-size: 22px;
    }
  }

  .social-icon {
    font-size: 24px;
  }

  .dropdown-item {
    color: var(--bs-primary);
  }

  .dropdown-item:hover,
  .dropdown-item:focus {
    color: var(--bs-white);
    background-color: var(--bs-primary);
  }

  button {
    margin-top: 6px;
  }

  h4 {
    font-weight: 700;
    font-size: 22px;
  }
}

.social-icon {
  font-size: 24px;
}

:deep{
  .search-container{
    input{
      width: 327px;
      border-color: var(--bs-gray-600);
    }
  }
}

.dropdown-item {
  color: var(--bs-primary);
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: var(--bs-white);
  background-color: var(--bs-primary);
}

.user-silhouette {
  max-width: 150px;
  padding: 15px;
  border-radius: 50%;
  background-color: #ECECEC;
}

.duplicate-name-container{
  color: #521B98;

  &__message{
    gap: 12px;
  }

  &__buttons{
    margin-top: 20px;
    padding-left: 24px;
  }

  .continue-adding-button{
    margin-top: 14px;
    cursor: pointer;
  }
}

@media (min-width: 768px) {
  .col-md-custom-4-5 {
    flex: 0 0 40%;
    max-width: 40%;
  }

  .col-md-custom-7-5 {
    flex: 0 0 60%;
    max-width: 60%;
  }
}

.gotoEmployerBtn {
  border-radius: 3.2px;
  width: 31px;
  height: 31px;
}

.my-profile-label {
  margin-bottom: 0.6rem;
}
</style>