<script setup>
import { computed, onMounted, ref } from 'vue'
import AlertNotification from '../AlertNotification.vue'
import BcsDynamicText from '../BcsDynamicText.vue'
import { useHamburguerMenuStore } from '../../stores/HamburguerMenu'
import { storeToRefs } from 'pinia'

const hamburguerMenuStore = useHamburguerMenuStore()
const { isMenuOpen } = storeToRefs(hamburguerMenuStore)
const { toggleMenu, closeMenu } = hamburguerMenuStore
const isScrolled = ref(false)

const toggleMenuNavbar = () => {
    toggleMenu()
}

const handleScroll = () => {
    isScrolled.value = window.scrollY > 0
}

onMounted(() => {
    window.addEventListener("scroll", handleScroll)
})

const props = defineProps({
    // Logo source for the navbar brand
    logoSrc: {
        type: String,
        required: true
    },
    // Logo alt text
    logoAlt: {
        type: String,
        default: 'logo'
    },
    // Active tab/menu item
    activePage: {
        type: String,
        default: 'home'
    },
    // User data (if user is logged in)
    userData: {
        type: Object,
        default: () => ({})
    },
    // Navigation mode: 'router' for app navigation, 'scroll' for landing page
    navMode: {
        type: String,
        default: 'router',
        validator: (value) => ['router', 'scroll'].includes(value)
    },
    // Navbar style: 'app' for application style, 'landing' for landing page style
    navStyle: {
        type: String,
        default: 'app',
        validator: (value) => ['app', 'landing'].includes(value)
    },
    // Navigation menu items
    navItems: {
        type: Array,
        default: () => [
            { id: 'home', label: 'Home', target: 'home' },
            { id: 'employers', label: 'Employers', target: 'employers' },
            { id: 'help', label: 'Help & Resources', target: 'help' }
        ]
    },
    // Profile dropdown menu items or CTA buttons for landing page
    actionItems: {
        type: Array,
        default: () => [
            { id: 'profile', label: 'Profile', target: 'profile', type: 'dropdown', showMobile: true },
            { id: 'help', label: 'Help', target: 'help', type: 'dropdown', showMobile: true },
            { id: 'logout', label: 'Log Out', target: 'logout', type: 'dropdown', showMobile: true }
        ]
    },
    // Show profile dropdown (for app style) or action buttons (for landing style)
    showActions: {
        type: Boolean,
        default: true
    }
})

// Define emits for click events
const emit = defineEmits(['nav-click', 'action-click', 'logo-click', 'logout'])

// Compute if user has a profile image
const profilePhoto = computed(() => {
    if (!props.userData) return null

    if (props.userData.temporaryPhotoURL && props.userData.temporaryPhotoURL !== '/user_silhouette.png') {
        return props.userData.temporaryPhotoURL
    }

    if (props.userData.profilePhotoUrl) {
        return props.userData.profilePhotoUrl
    }

    return null
})

const handleNavClick = (item) => {
    emit('nav-click', item)
}

const handleLogoClick = () => {
    emit('logo-click', { id: 'home', target: 'home' })
}

const handleActionClick = (item) => {
    if (item.id === 'logout') {
        emit('logout')
    } else {
        emit('action-click', item)
    }
}

const dropdownItems = computed(() => {
    return props.actionItems.filter(item => item.type === 'dropdown' || !item.type)
})

const buttonItems = computed(() => {
    return props.actionItems.filter(item => item.type === 'button')
})
</script>

<template>
    <!-- App style navbar -->
    <nav v-if="navStyle === 'app'" id="main-navbar"
        class="navbar navbar-expand-lg navbar-light bg-dark border-0 py-0 sticky-top h-61 z-index-1021">
        <div class="container-fluid">
            <div class="row row-adjustement h-61 ps-1">
                <!-- Logo section -->
                <div class="col-2 ps-4 d-inline-flex align-items-center" @click="handleLogoClick" role="button">
                    <img id="logo" :src="logoSrc" :alt="logoAlt" width="137" height="27.66" />
                </div>

                <!-- Navigation items section -->
                <div class="col-10 collapse navbar-collapse px-0" id="navbarSupportedContent"
                    style="margin-left: -0.9rem">
                    <ul class="navbar-nav nav-tabs me-auto h-100 border-0">
                        <li v-for="item in navItems" :key="item.id" class="nav-item bg-dark" role="presentation">
                            <button @click="handleNavClick(item)" class="nav-link text-light ps-3.5 pe-4 text-14 h-100"
                                :class="{
                                    active: activePage === item.id
                                }" :id="`${item.id}-tab`" type="button" role="tab" :aria-controls="item.id"
                                aria-selected="true">

                                <strong>{{ item.label }}</strong>
                            </button>
                        </li>
                    </ul>

                    <!-- User profile section -->
                    <div v-if="showActions" class="d-flex nav-item bg-dark pe-3">
                        <div class="dropdown">
                            <!-- Profile image or icon -->
                            <img v-if="profilePhoto" id="dropdownMenuProfile" data-bs-toggle="dropdown"
                                aria-expanded="false" role="button" tabindex="0" :src="profilePhoto" alt="profile img"
                                @keydown.enter="$event.target.click()" class="navbar-img rounded-circle" />
                            <i v-else class="bi bi-person-circle btn btn-outline-primary text-white nav-icon border-0"
                                type="button" id="dropdownMenuProfile" data-bs-toggle="dropdown" aria-expanded="false"
                                role="button" tabindex="0" @keydown.enter="$event.target.click()"></i>

                            <!-- Profile dropdown menu -->
                            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuProfile">
                                <li v-for="item in dropdownItems" :key="item.id" class="dropdown-item">
                                    <a class="hand-cursor dropdown-item" @click.prevent="handleActionClick(item)"
                                        href="#">
                                        {{ item.label }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nav>

    <!-- Landing style navbar with hamburger menu for responsive design -->
    <nav v-else :class="{ 'scrolled': isScrolled }" id="main-navbar" style="height: 80px;"
        class="navbar navbar-expand-lg navbar-light bg-dark border-0 py-0 sticky-top z-index-1030">
        <!-- mobile -->
        <div class="d-flex logo-mobile align-items-center" @click="handleLogoClick" role="button">
            <img id="logo" :src="logoSrc" :alt="logoAlt" width="137" height="27.66" />
        </div>

        <!-- Hamburger/X button for responsive design -->
        <button class="navbar-toggler button-mobile" type="button" @click="toggleMenuNavbar"
            aria-controls="mobileNavMenu" aria-expanded="false" aria-label="Toggle navigation">
            <span v-if="!isMenuOpen" class="toggler-icon">
                <i class="bi bi-list"></i>
            </span>
            <span v-else class="toggler-close">
                <i class="bi bi-x-lg"></i>
            </span>
        </button>

        <div class="container-fluid menu-desktop">
            <!-- desktop -->
            <div style="height: 100%;" class="row row-adjustement  ps-1">
                <div class="col-2 ps-4 d-inline-flex align-items-center logo-desktop" @click="handleLogoClick"
                    role="button">
                    <img id="logo" :src="logoSrc" :alt="logoAlt" width="137" height="27.66" />
                </div>

                <div class="col-10 collapse navbar-collapse px-0" id="navbarSupportedContent"
                    style="margin-left: -0.9rem">
                    <ul class="navbar-nav nav-tabs me-auto h-100 border-0">
                        <li v-for="item in navItems" :key="item.id" class="nav-item bg-dark" role="presentation">
                            <button @click="handleNavClick(item)" class="nav-link text-light ps-3.5 pe-4 text-14 h-100"
                                :class="{
                                    active: activePage === item.id
                                }" :id="`${item.id}-tab`" type="button" role="tab" :aria-controls="item.id"
                                aria-selected="true">
                                <strong>{{ item.label }}</strong>
                            </button>
                        </li>
                    </ul>

                    <div v-if="showActions" class="d-flex nav-item bg-dark pe-3">
                        <div class="d-flex align-items-center">
                            <a v-for="item in buttonItems" :key="item.id" href="#" :style="{
                                width: item.primary ? '170px' : '97px',
                                marginRight: '12px'
                            }" class="action-button " :class="[
                                item.primary ? 'btn-primary' : 'btn-info',
                                'btn'
                            ]" @click.prevent="handleActionClick(item)">
                                {{ item.label }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nav>

    <!-- Mobile dropdown menu -->
    <div v-if="navStyle === 'landing'" class="mobile-menu" :class="{ 'open': isMenuOpen, 'scrolled': isScrolled }">
        <!-- Menu items -->
        <div class="mobile-menu-items">
            <div v-for="(item, index) in navItems" :key="item.id" class="mobile-menu-item "
                :class="{ 'first-item-menu': index === 0 }">
                <div @click="handleNavClick(item); closeMenu()"
                    class="d-flex align-items-center justify-content-between">
                    <a class="mobile-menu-link">
                        {{ item.label }}
                    </a>
                    <i class="bi bi-chevron-right text-primary"></i>
                </div>
            </div>

            <!-- CTA button -->
            <div v-if="buttonItems.length > 0" class="mobile-menu-cta d-flex flex-column">
                <div v-for="item in buttonItems" :key="item.id">
                    <button v-if="item.showMobile" class="mobile-cta-button btn btn-primary"
                        @click.prevent="handleActionClick(item); closeMenu()">
                        {{ item.label }}
                    </button>
                </div>
            </div>

            <!-- alert -->
            <div class="mobile-menu-alert-container">
                <AlertNotification class="alert-mobile-menu" style="box-shadow: none !important;"
                    :show-close-button="false" :type-alert="'warning'">
                    <strong>
                        <BcsDynamicText :is-landing-page="true" text="alertNotificationNoLogIn" />
                    </strong>
                </AlertNotification>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.hand-cursor {
    cursor: pointer;
}

.row-adjustement {
    width: 100vw;
}

.h-61 {
    height: 61px !important;
}

.z-index-1021 {
    z-index: 1021;
}

.z-index-1030 {
    z-index: 1030;
}

.nav-link {
    border: none !important;
}

.navbar-nav .nav-link.active {
    background-color: var(--bs-teal) !important;
    color: var(--bs-white) !important;
    border-color: var(--bs-teal);
    border-bottom: 0 !important;

    strong {
        font-weight: 500 !important;
    }
}

.nav-tabs .nav-link:hover:not(.active) {
    background-color: var(--bs-primary);
    color: var(--bs-white) !important;
    border-color: var(--bs-primary);
}

.navbar-nav .nav-link {
    border-top: 5px transparent;
    height: 4.55vh;
}

.nav-tabs {
    --bs-nav-tabs-border-radius: 0;
}

.nav-icon {
    font-size: 20px;
}

.navbar-img {
    width: 38px;
    height: 38px;
}

.dropdown-item {
    color: var(--bs-primary);
}

.dropdown-item:hover,
.dropdown-item:focus {
    color: var(--bs-white);
    background-color: var(--bs-primary);
}

.logo-mobile,
.button-mobile,
.mobile-menu {
    display: none !important;
}

.menu-desktop {
    height: 100%;
}

.menu-desktop,
.logo-desktop {
    display: block;
}

.navbar {
    transition: height 0.3s ease, background-color 0.3s ease;
}

nav.scrolled {
    height: 50px !important;
    transition: height 0.3s ease;
}

.mobile-menu.scrolled {
    top: 50px !important;
    transition: top 0.3s ease;
}

.navbar .action-button {
    opacity: 0;
    transform: translate3d(0, 30px, 0);
    animation: navButtonFadeUp 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
    transform-style: preserve-3d;
    will-change: opacity, transform;
}

.navbar .action-button:nth-child(1) {
    animation-delay: 0.1s;
}

.navbar .action-button:nth-child(2) {
    animation-delay: 0.25s;
}

@keyframes navButtonFadeUp {
    0% {
        opacity: 0;
        transform: translate3d(0, 30px, 0);
    }
    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.navbar .action-button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    transition: all 0.3s ease;
}

.navbar .action-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

nav.scrolled .action-button {
    opacity: 1;
    transform: translate3d(0, 0, 0);
}

@media (max-width: 1030px) {
    .navbar {
        justify-content: space-between !important;
        height: 80px;
        width: 100%;
        position: sticky;
        transition: height 0.3s ease;
    }

    .logo-mobile,
    .button-mobile {
        display: block !important;
    }

    .menu-desktop,
    .logo-desktop {
        display: none !important;
    }

    .navbar-toggler {
        border: none !important;
        background: transparent !important;
        padding: 10px;
        margin-right: 3px;
        box-shadow: none !important;
        outline: none !important;

        &:focus {
            box-shadow: none !important;
            outline: none !important;
            border: none !important;
        }

        &:active {
            box-shadow: none !important;
            outline: none !important;
            border: none !important;
        }
    }

    .toggler-close {
        color: var(--bs-white);
        font-size: 24px;
        font-weight: lighter;
    }

    .toggler-icon {
        color: var(--bs-white) !important;
        font-size: 34px;
    }

    .mobile-menu {
        display: block !important;
        position: fixed;
        top: 80px;
        right: 0;
        width: 90%;
        height: calc(100vh - 50px);
        background-color: var(--bs-white);
        z-index: 1029;
        border-left: 1px solid #A3ACB1;
        transform: translateX(100%);
        transition: transform 0.3s ease, top 0.3s ease;
        overflow-y: auto;
    }

    .mobile-menu.open {
        transform: translateX(0);
        box-shadow: -6px 0px 6px 0px rgba(0, 0, 0, 0.12);
    }

    .mobile-menu-items {
        padding: 0;
    }

    .mobile-menu-item {
        width: 100%;
        padding-right: 20px;

        i {
            font-size: 28px;
        }
    }

    .mobile-menu-link {
        display: block;
        padding: 20px;
        color: var(--bs-primary);
        font-size: 26px;
        text-decoration: none;
        text-align: left;
        font-weight: 500;
    }

    .first-item-menu {
        padding-top: 25px;
    }

    .mobile-menu-alert-container {
        padding: 30px 20px 10px 20px;
        position: absolute;
        bottom: 28px;
    }

    .mobile-menu-cta {
        padding: 10px 20px;
        justify-content: center;
        margin-bottom: 20px;
        gap: 20px;
    }

    .mobile-cta-button {
        padding: 8px 25px;
        font-size: 20px;
        width: 100%;
    }

    .logo-mobile {
        padding-left: 10px;
    }
}
</style>