<script setup>
import Footer from './FooterSection.vue'
import Breadcrumbs from './BreadCrumbs.vue'
import BcsDynamicText from './BcsDynamicText.vue'
import { useFaqsStore } from '../stores/FaqsStore'
import { onMounted, ref, computed } from 'vue'
import TextWithDefinitions from './TextWithDefinitions.vue'
import BcsSearchDropdown from './BcsSearchDropdown.vue'
import BcsAccordion from './BcsAccordion.vue'

const faqStore = useFaqsStore()
const { getFaqs } = faqStore
const faqSections = ref([])
const displayedFaqSections = ref([])
const flattenedFaqs = ref([])

onMounted(async () => {
  const remoteFaqs = await getFaqs()

  if (remoteFaqs.value) {
    faqSections.value = Object.entries(remoteFaqs.value).map(([key, section]) => ({
      ...section,
      questionTitle: key,
      FaqList: section.FaqList ? JSON.parse(section.FaqList) : []
    }))
    displayedFaqSections.value = [...faqSections.value]
    flattenedFaqs.value = faqSections.value.flatMap(section =>
      section.FaqList.map(faq => ({
        ...faq,
        sectionId: section.sectionId
      }))
    )
  }
  window.scrollTo({ top: 0, behavior: 'smooth' })
})

const accordionSections = computed(() => {
  return displayedFaqSections.value.map(section => ({
    id: section.sectionId,
    title: section.sectionName,
    items: section.FaqList.map(faq => ({
      id: faq.MIGX_id,
      title: faq.faqTitle,
      content: faq.faqBody,
      MIGX_id: faq.MIGX_id,
      sectionId: section.sectionId
    }))
  }))
})

const handleSearch = ({ results }) => {
  if (results.length === 0) {
    displayedFaqSections.value = []
    return
  }
  const resultsBySection = {}
  results.forEach(result => {
    if (!resultsBySection[result.sectionId]) {
      resultsBySection[result.sectionId] = []
    }
    resultsBySection[result.sectionId].push(result)
  })

  displayedFaqSections.value = Object.keys(resultsBySection).map(sectionId => {
    const originalSection = faqSections.value.find(s => s.sectionId === sectionId)
    const section = originalSection || {
      sectionId: sectionId,
      sectionName: sectionId.charAt(0).toUpperCase() + sectionId.slice(1),
      MIGX_id: "search-" + sectionId,
      questionTitle: "Search Results"
    }
    const matchingFaqs = resultsBySection[sectionId];
    return {
      ...section,
      FaqList: matchingFaqs
    }
  })
}

const handleSelect = (faq) => {
  const section = displayedFaqSections.value.find(s => s.sectionId === faq.sectionId)
  if (section) {
    const targetFaq = section.FaqList.find(f => f.MIGX_id === faq.MIGX_id)
    if (targetFaq) {
      // The accordion will handle the selection
    }
  }
}

const handleClear = () => {
  displayedFaqSections.value = [...faqSections.value]
}
</script>

<template>
  <div class="container-fluid mx-5 px-5 mt-4">
    <div class="row">
      <div class="col-6">
        <Breadcrumbs></Breadcrumbs>
        <p class="text-28">
          <med>Frequently Asked Questions</med>
        </p>
        <p class="text-14">
          <BcsDynamicText text="faqsPageIntro" />
        </p>

        <div style="margin-bottom: 50px;">

          <BcsSearchDropdown placeholder="Search FAQs by keyword or sentence" :items="flattenedFaqs"
            :searchFields="['faqTitle', 'faqBody']" :maxSuggestions="10" @search="handleSearch" :arrayTerm="'faqTitle'"
            @select="handleSelect" @clear="handleClear" />
        </div>

        <BcsAccordion :sections="accordionSections" sectionTitleClass="header-22" itemTitleClass="text-primary"
          itemContentClass="text-14">
          <template #item-content="{ item }">
            <TextWithDefinitions :key="item.content" :text="item.content" />
          </template>
        </BcsAccordion>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <Footer class="px-5 mx-n3"></Footer>
    </div>
  </div>
</template>

<style scoped lang="scss">
.text-14,
.header-22 {
  margin-bottom: 30px;
}

:deep {
  .search-container {
    input {
      color: var(--bs-primary);
    }
  }
}
</style>
