<script setup>
import { computed, inject, onMounted, ref } from 'vue';
import BcsDynamicText from '../BcsDynamicText.vue';
import { RequestService } from '../../services/RequestService'
import { EmailUtilsService } from '../../services/EmailUtilsService'
import { handleErrors } from '../../utils/errorHandler'
import AlertNotification from '../AlertNotification.vue';
import emailSendedImage from '@/assets/images/email-sended.svg'
import { FirebaseStorageService } from '../../services/FirebaseStorageService'
const { getImageUrl } = FirebaseStorageService()

const { post } = RequestService()
const { getEmailTemplate, getEmailRecipient } = EmailUtilsService()

const formData = ref({
    fullName: '',
    email: '',
    message: '',
})

const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
const emailSended = ref(false)
const emailSendedError = ref(false)
const loading = ref(false)
const functionsBaseUrl = inject('functionsBaseUrl')
const imageUrl = ref('')


onMounted(async () => {
    imageUrl.value = await getImageUrl('sales-page/requestDemo', 'request-demo.png')
})

const isValidEmail = computed(() => {
    return emailRegex.test(formData.value.email)
})

const disabledButtons = computed(() => {
    return !formData.value.fullName ||
        !formData.value.email ||
        !isValidEmail.value ||
        !formData.value.message ||
        formData.value.fullName.trim().length < 1 ||
        formData.value.message.trim().length < 1
})

const resetForm = () => {
    emailSendedError.value = false
    formData.value = {
        fullName: '',
        email: '',
        message: '',
    }
}


const handleSubmit = async () => {
    try {
        loading.value = true
        formData.value.fullName = formData.value.fullName.trim()
        formData.value.message = formData.value.message.trim()
        await post('sendEmail', `${functionsBaseUrl}/email`, {
            type: 'REQUEST_DEMO',
            email: formData.value.email,
            data: {
                fullName: formData.value.fullName,
                message: formData.value.message,
                location: window.location.origin
            },
            emailTemplate: await getEmailTemplate('REQUEST_DEMO_EMAIL'),
            emailRecipient: await getEmailRecipient('REQUEST_DEMO_EMAIL')
        })
        formData.value = {
            fullName: '',
            email: '',
            message: ''
        }
        emailSended.value = true
    } catch (error) {
        handleErrors('Error sending email:', error)
        emailSendedError.value = true
        emailSended.value = false
    } finally {
        loading.value = false
    }
}


</script>

<template>
    <div class="request-demo-container d-flex">

        <div class="image">
            <img :src="imageUrl" alt="request-demo-image">
        </div>

        <div class="form">
            <h3>Request a Demo</h3>
            <div class="subtitle">
                <BcsDynamicText :isLandingPage="true" text="requestDemoIntro" />
            </div>

            <div v-if="!emailSended && !emailSendedError" class="input-container">
                <div class="mb-3 pt-2 input-group d-flex flex-column">
                    <span class="question-title text-start text-14">
                        <strong>Full Name<span class="error-hint">*</span></strong>
                    </span>
                    <div class="input-wrapper">
                        <input placeholder="Full Name" type="text" class="form-control text-16 form-input"
                            v-model="formData.fullName">
                    </div>
                </div>
                <div class="mb-3 pt-2 input-group d-flex flex-column">
                    <span class="question-title text-start text-14">
                        <strong>Email<span class="error-hint">*</span></strong>
                    </span>
                    <div class="input-wrapper">
                        <input placeholder="Email" type="text" class="form-control text-16 form-input"
                            v-model="formData.email">
                    </div>
                </div>
                <div style="padding-bottom: 30px;" class="pt-2 text-area-container d-flex flex-column">
                    <span class="question-title text-start text-14">
                        <strong>Message<span class="error-hint">*</span></strong>
                    </span>
                    <textarea class="form-control" rows="5" v-model="formData.message"
                        placeholder="e.g., I would like to book a demo of your software."></textarea>
                </div>

                <div class="actions-container d-flex w-100">

                    <button @click="handleSubmit" :class="{ 'disabled': disabledButtons }" type="button"
                        class="btn btn-secondary btn-submit btn-primary d-flex justify-content-center align-items-center"
                        style="width: 165px">
                        <span v-if="loading" class="spinner-border spinner-border-sm me-2" role="status"></span>
                        <span v-else>Request a Demo</span>
                    </button>

                    <button @click="resetForm" type="button" :class="{ 'disabled': disabledButtons }"
                        style="width: 132px"
                        class="btn btn-outline-secondary d-flex align-items-center btn-reset btn-info">
                        <i class="bi bi-x-circle rounded-circle d-flex justify-content-center align-items-center"></i>
                        &nbsp; Clear Form
                    </button>
                </div>

            </div>

            <div v-else class="email-success">

                <img v-if="emailSended" class="email-sended-img" :src="emailSendedImage"
                    alt="Email sent successfully" />

                <div class="email-sended-content">
                    <BcsDynamicText :isLandingPage="true" v-if="emailSended" text="emailSendedSucessCopy" />
                    <div class="send-another-message-container">
                        <button @click="emailSended = false, emailSendedError = false" class="btn btn-primary">Send
                            Another Message</button>
                    </div>
                </div>
            </div>

            <div v-if="emailSendedError" style="padding: 20px">
                <AlertNotification class="email-sended-error fw-bold" :typeAlert="'error'" :showCloseButton="false">
                    <BcsDynamicText :isLandingPage="true" text="emailSendedErrorCopy" />
                </AlertNotification>
            </div>

        </div>
    </div>
</template>
<style scoped lang="scss">
.request-demo-container {

    .subtitle {
        width: 71%;
        font-size: 16px;
    }

    .image {
        text-align: center;
        padding-top: 100px;

        img {
            width: 90%;
        }
    }

    .image,
    .form {
        width: 50%;
    }

    .form {
        h3 {
            padding-top: 85px;
            padding-bottom: 12px;
        }

        .input-container {
            margin-top: 11px;
        }

        .input-group {
            span {
                margin-bottom: 9px;
            }
        }

        textarea {
            resize: none;
            border: 1px solid var(--bs-gray-600) !important;
            margin-top: 9px !important;
        }

        .text-area-container {
            width: 71%;
        }

        .actions-container {
            gap: 12px;
            padding-bottom: 83px;

            .btn-submit,
            .btn-reset {
                height: 48px;
                font-size: 16px !important;
            }
        }
    }
}

.email-success {
    padding-bottom: 30px;
    width: 71%;
    text-align: center;
    margin-top: 40px;

    .send-another-message-container {
        margin-top: 20px;
    }
}

@media (max-width: 1030px) {

    .email-success {
        width: 100%;
    }
    .request-demo-container .form h3 {
        padding-top: 43px;
        text-align: center;
        font-family: 26px;
    }

    .request-demo-container .form .actions-container {
        flex-direction: column;
        padding: 0 20px;
        padding-bottom: 50px;

        .btn-submit,
        .btn-reset {
            width: 100% !important;
            justify-content: center;
            font-size: 20px !important;
        }
    }

    .request-demo-container {
        width: 100%;
        height: auto;

        .image {
            display: none;
        }

        .subtitle {
            font-size: 18px;
            width: 100%;
            padding: 0 20px;
        }

        .form {
            width: 100%;

            .input-group {
                width: 100%;
                padding: 0 20px;
            }

            .text-area-container {
                width: 100%;
                padding: 0 20px;
            }

            .input-wrapper {
                width: 100%;
            }

            .form-input {
                height: 50px;
            }
        }
    }
}
</style>