<script setup>
import { computed, onMounted, ref } from 'vue'
import {
    getDatabase,
    ref as dbRef,
    get as dbGet,
} from 'firebase/database'
import { getApp } from 'firebase/app'
import { handleErrors } from '../../utils/errorHandler'
import TextWithDefinitions from '../TextWithDefinitions.vue'
import BcsAccordion from '../BcsAccordion.vue'
import { FirebaseStorageService } from '../../services/FirebaseStorageService'
import { useIntersectionObserver } from '../../services/IntersectionObserver'
const { getImageUrl } = FirebaseStorageService()
const { observe } = useIntersectionObserver()

const db = getDatabase(getApp())
const ourProductItems = ref([])
const selectedItemId = ref('')
const selectedItem = ref(null)
const isImageLoading = ref(false)

const accordionItems = ref([])
const accordionItemsSections = ref([])
const animationTriggered = ref(false)
const animationImageTriggered = ref(false)
const mobileAnimationTriggered = ref(false)
const sectionRef = ref(null)
const mobileSectionRef = ref(null)
const imageContainerRef = ref(null)

onMounted(async () => {
    await getAccordionItems()
    await getItems()
    observe(sectionRef.value, () => {
        if (!animationTriggered.value) {
            animationTriggered.value = true
        }
    })
    observe(mobileSectionRef.value, () => {
        if (!mobileAnimationTriggered.value) {
            mobileAnimationTriggered.value = true
        }
    }, {
        root: null,
        rootMargin: '0px',
        threshold: 0.45
    })
    observe(imageContainerRef.value, () => {
        if (!animationImageTriggered.value) {
            animationImageTriggered.value = true
        }
    }, {
        root: null,
        rootMargin: '0px',
        threshold: 0.1
    })
})

const preloadImages = (items) => {
    items.forEach(item => {
        if (item.imageUrl) {
            const img = new Image()
            img.src = item.imageUrl
        }
    })
}

const safeGetImageUrl = async (folder, imageName) => {
    if (!imageName) return ''

    try {
        const url = await getImageUrl(folder, imageName);
        return url || ''
    } catch (error) {
        handleErrors(`Error loading image ${imageName} from ${folder}:`, error);
        return ''
    }
}

const getItems = async () => {
    try {
        const itemsRef = dbRef(db, 'LANDING_PAGE/OurProduct/items')
        const snapshot = await dbGet(itemsRef)
        if (snapshot.exists()) {
            const items = snapshot.val()
            ourProductItems.value = await Promise.all(items.map(async (item) => ({
                ...item,
                imageUrl: await safeGetImageUrl('sales-page/ourProduct', item.image)
            })))
            if (ourProductItems.value.length > 0) {
                preloadImages(ourProductItems.value)
                selectItem(ourProductItems.value[0].id)
            }
        }
    } catch (err) {
        handleErrors('Error fetching items:', err)
    }
}

const selectItem = (itemId) => {
    isImageLoading.value = true

    selectedItemId.value = itemId
    const item = ourProductItems.value.find(item => item.id === itemId)
    if (item) {
        selectedItem.value = item

        setTimeout(() => {
            isImageLoading.value = false
        }, 300)
    }
}

const getAccordionItems = async () => {
    try {
        const accordionItemsRef = dbRef(db, 'LANDING_PAGE/OurProduct/accordion-items')
        const snapshot = await dbGet(accordionItemsRef)
        if (snapshot.exists()) {
            accordionItems.value = snapshot.val()

            accordionItemsSections.value = await Promise.all(
                Object.entries(accordionItems.value).map(async ([key, section]) => {
                    let productList = section.ourProductList ? JSON.parse(section.ourProductList) : [];

                    const processedProductList = await Promise.all(
                        productList.map(async (product) => {
                            const imageUrl = product.image ?
                                await safeGetImageUrl('sales-page/ourProduct', product.image) :
                                '';

                            return {
                                ...product,
                                imageUrl
                            }
                        })
                    );

                    return {
                        ...section,
                        questionTitle: key,
                        ProductList: processedProductList
                    };
                })
            );
        }
    } catch (err) {
        handleErrors('Error fetching accordion items:', err)
    }
}

const accordionSections = computed(() => {
    return accordionItemsSections.value.map(section => ({
        id: section.sectionId,
        title: section.sectionName,
        items: section.ProductList.map(product => ({
            id: product.MIGX_id,
            title: product.ourProductTitle,
            content: product.ourProductBody,
            MIGX_id: product.MIGX_id,
            sectionId: section.sectionId,
            imageUrl: product.imageUrl
        }))
    }))
})
</script>

<template>
    <div ref="sectionRef" class="d-flex our-product-container">
        <div class="options-container">
            <h3 :class="{ 'animate-active': animationTriggered }" class="animate-element" style="--delay: 0s">Our
                product
            </h3>
            <div v-for="(item, index) in ourProductItems" :key="item.id" :class="[
                'option text-primary animate-element',
                { 'active': selectedItemId === item.id },
                { 'animate-active': animationTriggered }
            ]" :style="`--delay: ${0.2 + (index * 0.15)}s`" @click="selectItem(item.id)">
                {{ item.title }}
            </div>
        </div>
        <div class="content" v-if="selectedItem">
            <div class="content-text">
                <h4 :class="{ 'animate-active': animationTriggered }" class="animate-element" style="--delay: 1.5s">
                    {{ selectedItem.title }}
                </h4>
                <p :class="{ 'animate-active': animationTriggered }" class="subtitle animate-element"
                    style="--delay: 1.7s">
                    <TextWithDefinitions :key="selectedItem.subtitle" :text="selectedItem.subtitle" />
                </p>
            </div>

            <div ref="imageContainerRef" class="content-image d-flex">
                <div :class="{ 'animate-active': animationImageTriggered }" class="rectangle animate-element"
                    style="--delay: 1s"></div>
                <div class="image-container">
                    <div :class="[
                        'image animate-zoom',
                        { 'animate-active': animationImageTriggered && !isImageLoading },
                        { 'loading': isImageLoading },
                    ]" style="--delay: 1.2s" v-if="selectedItem.imageUrl">
                        <img :class="selectedItem.class" :src="selectedItem.imageUrl" :alt="selectedItem.title">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div ref="mobileSectionRef" class="our-product-container-mobile">
        <h3 class="text-center">Our product</h3>

        <BcsAccordion :isLandingPage="true" :externalAnimationTrigger="mobileAnimationTriggered"
            :showSectionTitle="false" :sections="accordionSections" sectionTitleClass="header-22"
            itemTitleClass="text-primary" itemContentClass="our-product-accordion">
            <template #item-content="{ item }">
                <TextWithDefinitions style="margin-bottom: 20px;" :key="item.content" :text="item.content" />
                <div class="separator"></div>
                <img style="margin-bottom: 20px;" v-if="item.imageUrl" :src="item.imageUrl" alt="">
            </template>

        </BcsAccordion>


    </div>
</template>

<style scoped lang="scss">
.our-product-container-mobile {
    display: none;
}

.animate-element {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease, transform 0.5s ease;
    transition-delay: var(--delay, 0s);
}

.animate-zoom {
    opacity: 0;
    transform: scale(0.85);
    transition: opacity 0.8s ease, transform 0.8s ease;
    transition-delay: var(--delay, 0s);
}

.animate-zoom.animate-active {
    opacity: 1;
    transform: scale(1);
}

.compliance-report-risk {
    padding-right: 80px;
}

.compliance-calendar {
    padding: 0 7px 12px 3px;
}

.calendar-reminders {
    padding: 0 15px 20px 14px;
}

.white-label-options {
    padding: 0 166.62px 20px 152px;
}

.planning-guide {
    padding: 0 11px 20px 7px;
}

.activity-log {
    padding: 0 8px 20px 4px;
}

.animate-zoom.loading {
    opacity: 0;
    transform: scale(0.85);
}

.animate-active {
    opacity: 1;
    transform: translateY(0);
}

.image-container {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
}

.risk-score {
    width: 90% !important;
}

.our-product-container {
    height: auto;
    overflow-y: auto;
    overflow-x: hidden;
    // min-height: 1200px;

    .options-container {
        width: 30%;
        padding-left: 82px;
        border-right: 1px solid rgba(33, 37, 41, 0.25);

        h3 {
            margin-top: 85px;
            margin-bottom: 26px;
            padding-left: 17px;
        }

        .option {
            padding: 12px 13px 10px 13px;
            font-size: 20px;
            font-weight: 400 !important;
            cursor: pointer;
        }

        .option.active {
            background-color: var(--bs-dark);
            border-left: 4px solid var(--bs-teal);
            color: var(--bs-white) !important;
        }
    }

    .content {
        width: 70%;

        &-text {
            padding: 155px 59px 40px 44px;
            border-bottom: 1px solid rgba(33, 37, 41, 0.25);
        }

        &-image {
            .image {
                width: 100%;
                height: 100%;
                padding-top: 40px;
                transform-origin: center center;

                img {
                    width: 100%;
                    transition: opacity 0.3s ease;
                }
            }
        }

        h4 {
            font-size: 28px;
            font-weight: 500;
            margin-bottom: 0;
        }

        .subtitle {
            font-size: 16px;
            margin-top: 15px;
            color: var(--bs-gray-900);
        }
    }
}

:deep .white-space-pre-wrap {
    white-space: normal !important;

    ul {
        margin: 0 !important;
        list-style: none;
        padding-top: 20px;
        padding-left: 0 !important;
    }

    li {
        position: relative;
        padding-left: 30px;
        margin-bottom: 20px;
    }

    li::before {
        content: "\F26A";
        font-family: "bootstrap-icons";
        position: absolute;
        left: 0;
        top: -3px;
        color: var(--bs-green);
        font-size: 21px;
    }
}

@media (max-width: 1030px) {
    .our-product-container {
        display: none !important;
    }

    .separator {
        margin-top: 24px;
        border-bottom: 1px solid var(--bs-gray-300);
    }

    :deep {
        .our-product-accordion {
            font-size: 18px !important;
        }
    }

    :deep(.collapsed-item.accordion-item) {
        border-left: none !important;
        border-top: none !important;
        border-right: none !important;
    }

    :deep(.collapsed.accordion-button) {
        border-bottom: 1px solid var(--bs-gray-300) !important;
    }

    :deep .accordion-button {
        box-shadow: none !important;
        font-size: 18px !important;

        &:not(.collapsed) {
            background-color: var(--bs-gray-200);
            border-bottom: 1px solid var(--bs-gray-300) !important;

            strong {
                color: #521B98 !important;
            }

        }
    }

    .our-product-accordion {
        img {
            width: -webkit-fill-available;
            height: auto;
            margin-top: 20px;
        }
    }

    .our-product-container-mobile {
        display: block;
        padding-left: 21px;
        padding-right: 20px;

        h3 {
            margin-top: 47px;
            margin-bottom: 30px;
        }
    }
}
</style>