<script setup>
import { ref, computed, onMounted, onBeforeUnmount, watch } from 'vue';
import {
    getDatabase,
    ref as dbRef,
    get as dbGet,
} from 'firebase/database'
import { getApp } from 'firebase/app'
import { handleErrors } from '../../utils/errorHandler'
import { useIntersectionObserver } from '../../services/IntersectionObserver';

const { observe } = useIntersectionObserver()
const db = getDatabase(getApp())
const testimonials = ref([])
const displayedMobileTestimonials = ref([])
const currentPage = ref(0)
const itemsPerPage = 3
const totalPages = computed(() => Math.max(1, Math.ceil(testimonials.value.length / itemsPerPage)))
const isMobile = ref(false)

const displayedTestimonials = computed(() => {
    if (isMobile.value) {
        return displayedMobileTestimonials.value
    }
    
    if (testimonials.value.length <= itemsPerPage) {
        return testimonials.value
    }
    
    const result = [];
    for (let i = 0; i < itemsPerPage; i++) {
        const index = (currentPage.value * itemsPerPage + i) % testimonials.value.length
        result.push(testimonials.value[index])
    }
    return result
})

const animationTriggered = ref(false)
const sectionRef = ref(null)

const handleMobileScroll = (event) => {
    if (!isMobile.value || !testimonials.value.length) return;
    
    const container = event.target
    const scrollWidth = container.scrollWidth
    const scrollLeft = container.scrollLeft
    const width = container.clientWidth
    
    if (scrollLeft + width >= scrollWidth * 0.7) {
        addMoreTestimonials()
    }
}

const addMoreTestimonials = () => {
    if (testimonials.value.length === 0) return;
    
    displayedMobileTestimonials.value = [...displayedMobileTestimonials.value, ...testimonials.value];
}

watch([testimonials, isMobile], ([newTestimonials, newIsMobile]) => {
    if (newIsMobile && newTestimonials.length > 0) {
        displayedMobileTestimonials.value = [...newTestimonials, ...newTestimonials, ...newTestimonials]
    }
}, { immediate: true })

onMounted(async () => {
    await getTestimonials()
    checkMobileView()
    
    window.addEventListener('resize', checkMobileView)
    
    const container = document.querySelector('.testimonial-card-container');
    if (container) {
        container.addEventListener('scroll', handleMobileScroll);
    }
    
   
    observe(sectionRef.value, () => {
        if (!animationTriggered.value && !isMobile.value) {
            animationTriggered.value = true
        }
    }, {
        root: null,
        rootMargin: '40px',
        threshold: 0.8
    })
})

onBeforeUnmount(() => {
    window.removeEventListener('resize', checkMobileView)
    const container = document.querySelector('.testimonial-card-container');
    if (container) {
        container.removeEventListener('scroll', handleMobileScroll);
    }
})

const checkMobileView = () => {
    isMobile.value = window.innerWidth <= 1030
    
    if (isMobile.value) {
        animationTriggered.value = true
    }
}

const getTestimonials = async () => {
    try {
        const itemsRef = dbRef(db, 'LANDING_PAGE/Testimonials/items')
        const snapshot = await dbGet(itemsRef)
        if (snapshot.exists()) {
            testimonials.value = snapshot.val()
            if (isMobile.value) {
                displayedMobileTestimonials.value = [...testimonials.value, ...testimonials.value, ...testimonials.value];
            }
        }
    } catch (err) {
        handleErrors('Error fetching testimonials:', err)
    }
}

const nextPage = () => {
    currentPage.value = (currentPage.value + 1) % totalPages.value;
}

const prevPage = () => {
    currentPage.value = (currentPage.value - 1 + totalPages.value) % totalPages.value;
}
</script>

<template>
    <div ref="sectionRef" class="what-people-are-saying-container text-center">
        <h3 :class="{ 'animate-active': animationTriggered || isMobile, 'animate-element': !isMobile }" style="--delay: 0s">What People
            Are
            Saying</h3>

        <div class="testimonial-card-container">
            <div v-if="!isMobile && testimonials.length > itemsPerPage" :class="{ 'animate-active': animationTriggered, 'animate-element': !isMobile }"
                class="nav-arrow prev action-button" style="--delay: 0.2s" @click="prevPage"
                aria-label="Previous testimonials">
                <i class="bi bi-chevron-left"></i>
            </div>

            <div v-for="(testimonial, index) in displayedTestimonials" :key="currentPage + '-' + index"
                :class="[
                    { 'animate-active': animationTriggered || isMobile },
                    { 'animate-slide-from-right': !isMobile }
                ]"
                class="testimonial-card"
                :style="!isMobile ? `--delay: ${0.8 + (index * 0.15)}s` : ''">
                <div class="icon-wrapper">
                    <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill="none">
                        <g clip-path="url(#clip0_87_4324)">
                            <path
                                d="M8.12258 33.8856C8.41274 34.1768 8.63508 34.5285 8.77376 34.9156C8.91243 35.3026 8.96403 35.7155 8.92488 36.1247C8.72841 38.0189 8.35594 39.8906 7.81228 41.7156C11.7119 40.8127 14.0937 39.7672 15.1755 39.2193C15.7891 38.9085 16.4958 38.8349 17.1603 39.0124C19.0652 39.5203 21.0285 39.7759 23 39.7728C34.1706 39.7728 42.5682 31.9259 42.5682 23.0001C42.5682 14.077 34.1706 6.22734 23 6.22734C11.8294 6.22734 3.43181 14.077 3.43181 23.0001C3.43181 27.1038 5.1566 30.9112 8.12258 33.8856ZM6.74442 44.8018C6.08209 44.9331 5.41761 45.0533 4.75126 45.1624C4.19217 45.2519 3.76726 44.6704 3.9881 44.1505C4.23632 43.5651 4.46382 42.9712 4.67019 42.3698L4.67858 42.3418C5.37185 40.3291 5.93653 38.0145 6.1434 35.8592C2.71338 32.4207 0.636353 27.9201 0.636353 23.0001C0.636353 12.1928 10.6497 3.43188 23 3.43188C35.3503 3.43188 45.3636 12.1928 45.3636 23.0001C45.3636 33.8073 35.3503 42.5682 23 42.5682C20.785 42.5712 18.5792 42.2836 16.4391 41.7128C14.9854 42.448 11.8573 43.7871 6.74442 44.8018Z"
                                fill="white" />
                            <path
                                d="M11.8182 16.0114C11.8182 15.6407 11.9654 15.2852 12.2276 15.0231C12.4897 14.761 12.8452 14.6137 13.2159 14.6137H32.7841C33.1548 14.6137 33.5103 14.761 33.7724 15.0231C34.0345 15.2852 34.1818 15.6407 34.1818 16.0114C34.1818 16.3821 34.0345 16.7376 33.7724 16.9998C33.5103 17.2619 33.1548 17.4092 32.7841 17.4092H13.2159C12.8452 17.4092 12.4897 17.2619 12.2276 16.9998C11.9654 16.7376 11.8182 16.3821 11.8182 16.0114ZM11.8182 23.0001C11.8182 22.6294 11.9654 22.2738 12.2276 22.0117C12.4897 21.7496 12.8452 21.6023 13.2159 21.6023H32.7841C33.1548 21.6023 33.5103 21.7496 33.7724 22.0117C34.0345 22.2738 34.1818 22.6294 34.1818 23.0001C34.1818 23.3708 34.0345 23.7263 33.7724 23.9884C33.5103 24.2505 33.1548 24.3978 32.7841 24.3978H13.2159C12.8452 24.3978 12.4897 24.2505 12.2276 23.9884C11.9654 23.7263 11.8182 23.3708 11.8182 23.0001ZM11.8182 29.9887C11.8182 29.618 11.9654 29.2625 12.2276 29.0004C12.4897 28.7382 12.8452 28.591 13.2159 28.591H24.3977C24.7684 28.591 25.1239 28.7382 25.3861 29.0004C25.6482 29.2625 25.7954 29.618 25.7954 29.9887C25.7954 30.3594 25.6482 30.7149 25.3861 30.977C25.1239 31.2392 24.7684 31.3864 24.3977 31.3864H13.2159C12.8452 31.3864 12.4897 31.2392 12.2276 30.977C11.9654 30.7149 11.8182 30.3594 11.8182 29.9887Z"
                                fill="white" />
                        </g>
                        <defs>
                            <clipPath id="clip0_87_4324">
                                <rect width="44.7273" height="44.7273" fill="white"
                                    transform="translate(0.636353 0.636475)" />
                            </clipPath>
                        </defs>
                    </svg>
                </div>

                <div class="testimonal-card__text">
                    <div class="testimonial-quote-container">
                        <div class="testimonial-text">"{{ testimonial.quote }}"</div>
                    </div>
                    <div class="author-info">
                        <div class="author-name">{{ testimonial.author }}</div>
                        <div class="author-company">{{ testimonial.company }}</div>
                    </div>
                </div>
            </div>

            <div v-if="!isMobile && testimonials.length > itemsPerPage" :class="[
                'nav-arrow next action-button',
                { 'animate-active': animationTriggered },
                { 'animate-element': !isMobile }
            ]" style="--delay: 0.8s" @click="nextPage" aria-label="Next testimonials">
                <i class="bi bi-chevron-right"></i>
            </div>
        </div>

    </div>
</template>

<style scoped lang="scss">
.what-people-are-saying-container {
    height: 555px;
    background-color: var(--bs-dark);
    position: relative;
    overflow: hidden;

    h3 {
        color: var(--bs-white);
        padding-top: 90px;
        padding-bottom: 33px;
    }
}

.animate-element {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1s ease, transform 1s ease;
    transition-delay: var(--delay, 0s);
}

.animate-slide-from-right {
    opacity: 0;
    transform: translateX(60px);
    transition: opacity 1s ease, transform 1s ease;
    transition-delay: var(--delay, 0s);
}

.animate-active {
    opacity: 1;
    transform: translateY(0);
}

.animate-slide-from-right.animate-active {
    opacity: 1;
    transform: translateX(0);
}

.action-button {
    color: var(--bs-white);
    font-size: 24px;
    cursor: pointer;
}

.action-button-disabled {
    cursor: not-allowed;
    color: var(--bs-gray-600);
}

.testimonal-card__text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.testimonial-card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 100px;
    gap: 30px;
}

.testimonial-card {
    position: relative;
    width: 357px;
    height: 270px;
    background-color: var(--bs-white);
    border-radius: 4px;
    padding: 30px;
    text-align: center;
    margin-top: 50px;
}

.icon-wrapper {
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
    width: 82px;
    height: 82px;
    background-color: var(--bs-teal);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 8px solid var(--bs-dark);
}

.testimonial-quote-container {
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.testimonial-text {
    margin-top: 20px;
    font-size: 18px;
    font-weight: 500;
}

.author-info {
    height: 50px;
    margin-top: 10px;
}

.author-name {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 5px;
}

.author-company {
    font-size: 14px;
    color: #8C8C8C;
}

.swipe-indicator {
    color: var(--bs-white);
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        opacity: 0.5;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0.5;
    }
}

@media (max-width: 1030px) {
    .what-people-are-saying-container {
        height: auto;
        min-height: 500px;
        padding-bottom: 40px;
    }

    .testimonal-card__text {
        justify-content: normal !important;
    }

    .what-people-are-saying-container h3 {
        padding-top: 43px;
        padding-bottom: 35px;
    }

    .testimonial-card-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 20px;
        gap: 15px;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        -webkit-overflow-scrolling: touch;
        scroll-behavior: smooth;
        scrollbar-width: none;
        -ms-overflow-style: none;
        padding-bottom: 10px;
    }

    .testimonial-card-container::-webkit-scrollbar {
        display: none;
    }

    .testimonial-card {
        scroll-snap-align: center;
        min-width: 280px;
        width: 280px;
        flex-shrink: 0;
        margin-right: 0;
        height: 255px;
    }

    .testimonial-quote-container {
        height: 125px;
        max-height: 125px;
        display: block;
    }

    .testimonial-text {
        font-size: 16px;
        margin-top: 10px;
    }

    .author-info {
        height: 45px;
        margin-top: 15px;
        padding-top: 5px;
    }

    .author-name {
        margin-bottom: 2px;
    }

    .nav-arrow {
        display: none;
    }
}
</style>