<script setup>
import { ref } from 'vue'

const props = defineProps({
  // Array of sections, each containing items
  sections: {
    type: Array,
    required: true,
    validator: (value) => {
      return value.every(section => 
        typeof section.id !== 'undefined' &&
        typeof section.title !== 'undefined' &&
        Array.isArray(section.items) &&
        section.items.every(item => 
          typeof item.id !== 'undefined' &&
          typeof item.title !== 'undefined' &&
          typeof item.content !== 'undefined'
        )
      )
    }
  },
  // Optional section title class
  sectionTitleClass: {
    type: String,
    default: 'header-22'
  },
  showSectionTitle: {
    type: Boolean,
    default: true
  },
  // Optional item title class
  itemTitleClass: {
    type: String,
    default: 'text-primary'
  },
  // Optional item content class
  itemContentClass: {
    type: String,
    default: 'text-14'
  },
  // Whether to allow multiple items to be open at once
  allowMultiple: {
    type: Boolean,
    default: false
  },
  // Whether to animate items (for landing page)
  isLandingPage: {
    type: Boolean,
    default: false
  },
  // Control de animación externo (obligatorio cuando isLandingPage es true)
  externalAnimationTrigger: {
    type: Boolean,
    default: false
  }
})

const selectedAccordions = ref(new Set())

const toggleAccordion = (sectionId, itemId) => {
  const accordionId = `${sectionId}-${itemId}`
  
  if (props.allowMultiple) {
    if (selectedAccordions.value.has(accordionId)) {
      selectedAccordions.value.delete(accordionId)
    } else {
      selectedAccordions.value.add(accordionId)
    }
  } else {
    if (selectedAccordions.value.has(accordionId)) {
      selectedAccordions.value.clear()
    } else {
      selectedAccordions.value.clear()
      selectedAccordions.value.add(accordionId)
    }
  }
}

const isSelected = (sectionId, itemId) => {
  return selectedAccordions.value.has(`${sectionId}-${itemId}`)
}
</script>

<template>
  <div class="bcs-accordion">
    <div v-for="section in sections" :key="section.id" class="accordion-section">
      <!-- Section title if provided -->
      <p v-if="section.title && showSectionTitle" :class="sectionTitleClass">
        <strong>{{ section.title }}</strong>
      </p>

      <!-- Accordion items -->
      <div class="accordion" :id="'accordion' + section.id">
        <div
          v-for="(item, index) in section.items"
          :key="section.id + '-' + item.id"
          :class="[
            'accordion-item',
            {'collapsed-item': isSelected(section.id, item.id)},
            {'animate-fade-up': isLandingPage},
            {'animate-active': isLandingPage && externalAnimationTrigger}
          ]"
          :style="isLandingPage ? `--delay: ${0.15 + (index * 0.12)}s` : ''"
        >
          <h2 class="accordion-header" :id="'heading' + section.id + item.id">
            <button
              class="accordion-button"
              :class="{
                collapsed: !isSelected(section.id, item.id)
              }"
              type="button"
              @click="toggleAccordion(section.id, item.id)"
              :aria-expanded="isSelected(section.id, item.id)"
              :aria-controls="'collapse' + section.id + item.id"
            >
              <strong :class="itemTitleClass">{{ item.title }}</strong>
            </button>
          </h2>
          <div
            :id="'collapse' + section.id + item.id"
            class="accordion-collapse collapse"
            :class="{ show: isSelected(section.id, item.id) }"
            :aria-labelledby="'heading' + section.id + item.id"
            :data-bs-parent="allowMultiple ? null : '#accordion' + section.id"
          >
            <div class="accordion-body">
              <div :class="itemContentClass">
                <slot name="item-content" :item="item">
                  {{ item.content }}
                </slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.bcs-accordion {
  .accordion {
    margin-bottom: 41px;
  }

  .animate-fade-up {
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
    transition-delay: var(--delay, 0s);
  }

  .animate-fade-up.animate-active {
    opacity: 1;
    transform: translateY(0);
  }

  .accordion-button {
    padding: 1rem 1.25rem;
    font-size: 1rem;
    background-color: white;
    border: none;
    box-shadow: none;
    
    &:not(.collapsed) {
      
      &::after {
        background-image: var(--bs-accordion-btn-icon);
        transform: var(--bs-accordion-btn-icon-transform);
      }
    }
  }

  .accordion-collapse {
    transition: max-height 0.3s ease-out;
    max-height: 0;
    overflow: hidden;
    
    &.show {
      max-height: fit-content;
    }
  }

  .accordion-body {
    padding: 1rem 1.25rem;
  }

  .header-22 {
    font-size: 1.375rem;
    margin-bottom: 1.875rem;
  }

  .text-14 {
    font-size: 0.875rem;
    margin-bottom: 0;
  }
}
</style>