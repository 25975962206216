<script setup>
  // import { computedAsync } from '@vueuse/core'
  import { ref, watchEffect, defineEmits } from 'vue'
  import TextWithDefinitions from './TextWithDefinitions.vue'
  import { useTemplateVariablesStore } from '../stores/TemplateVariablesStore'

  const templateStore = useTemplateVariablesStore()
  const { getDefinitionById } = templateStore
  const props = defineProps(['text', 'variables', 'isCenterModal', 'isLandingPage'])
  const emit = defineEmits(['update:completeText'])

  const completeText = ref('')
  watchEffect(async () => {
    let text = await getDefinitionById(props.text, '', props.variables, '', props.isLandingPage ? true : false)
    completeText.value = text.body
    emit('update:completeText', completeText.value)
  })
</script>

<template>
  <TextWithDefinitions
    :key="completeText"
    :text="completeText"
    :isCenterModal="!!props.isCenterModal" />
</template>

<style scoped></style>
