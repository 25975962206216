<script setup>
  import { ref, onMounted, inject } from 'vue'
  import { useRouter, useRoute } from 'vue-router'
  import { useAuthStore } from '../shared/stores/AuthStore'
  import { RequestService } from '../shared/services/RequestService'
  import logo from '@/assets/images/loginLogo.png'
  import { handleErrors } from '../shared/utils/errorHandler'
  import { EmailUtilsService } from '../shared/services/EmailUtilsService'
  import { useTemplateVariablesStore } from '../shared/stores/TemplateVariablesStore'
  const { getEmailTemplate, getEmailRecipient } = EmailUtilsService()
  const { post } = RequestService()
  const functionsBaseUrl = inject('functionsBaseUrl')
  const router = useRouter()
  const route = useRoute()
  const authStore = useAuthStore()
  const templateStore = useTemplateVariablesStore()
  const { resetTemplateData } = templateStore

  const showError = ref(false)
  const usernameError = ref(false)
  const showPass = ref(false)
  const showSuccessMessage = ref(false)
  const loading = ref(false)
  const buttonLoading = ref(false)
  const showResetForm = ref(false)
  const resetEmailSent = ref(false)
  const resetEmail = ref('')
  const resetEmailError = ref(false)
  const successMessage = ref('')
  

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const formState = ref({
    username: '',
    password: '',
    returnUrl: '/tmplogin.html',
    service: 'login',
    Login: 'Login'
  })

  const closeSuccessMessage = () => {
    showSuccessMessage.value = false
  }

  const toggleResetForm = () => {
    showResetForm.value = !showResetForm.value
    resetEmailSent.value = false
    resetEmail.value = formState.value.username
    showError.value = false
    resetEmailError.value = false
    closeSuccessMessage()
  }

  const sendResetEmail = async () => {
    if (!resetEmail.value) return

    resetEmailError.value = false

    if (!validateEmail(resetEmail.value)) {
      resetEmailError.value = true
      return
    }

    buttonLoading.value = true
    showError.value = false

    try {
      // Send custom email through Firebase function
      await post('sendEmail', `${functionsBaseUrl}/email`, {
        type: 'AUTH',
        subtype: 'PASSWORD_RESET',
        email: resetEmail.value,
        emailTemplate: await getEmailTemplate('PASSWORD_RESET'),
        emailRecipient: await getEmailRecipient('TEST_EMAIL_RECIPIENT')
      })
    } catch (err) {
      showError.value = false
    } finally {
      resetEmailSent.value = true
      buttonLoading.value = false
    }
  }

  // const sendResetToNewEmail = () => {
  //   resetEmailSent.value = false
  //   resetEmail.value = ''
  // }

  async function login() {
    try {
      const { success } = await authStore.login(formState.value.username, formState.value.password)

      if (success) {
        resetTemplateData()
        router.push({ path: '/home' })
      } else {
        showError.value = true
        formState.value.password = ''
      }
    } catch (error) {
      showError.value = true
      formState.value.password = ''
    }
  }

  onMounted(() => {
    const status = route.query.status
    const resetPass = route.query.resetPass
    const encodedEmail = route.query.e

    if (status === 'created' || status === 'updated') {
      showSuccessMessage.value = true
      successMessage.value =
        status === 'updated' ?
          `You've successfully updated your password!`
          : `You've successfully created your password!`
    }

     if (resetPass === 'true') {
      showResetForm.value = true
      if (encodedEmail) {
        try {
          resetEmail.value = atob(encodedEmail)           
        } catch (e) {
          handleErrors('Invalid email encoding')
        }
      }
    }
  })
</script>

<template>
  <div class="bg-white loading-overlay" v-if="loading">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>

  <div class="container-fluid bg-dark pt-5 px-5">
    <div class="row d-flex justify-content-center pt-3 login-card-container">
      <div class="container bg-white rounded pb-2 pt-5 login-card">
        <div v-if="showSuccessMessage" class="pb-1 row d-flex justify-content-center">
          <div
            class="alert alert-success alert-dismissible fade show col-md-8 shadow-sm"
            role="alert">
            <div class="d-flex align-items-center">
              <i class="bi bi-check-circle-fill me-2"></i>
              <div class="text-14">
                <strong>
                  {{ successMessage }}
                </strong>
              </div>
            </div>
            <button
              type="button"
              class="btn-close"
              @click="closeSuccessMessage"
              aria-label="Close"></button>
          </div>
        </div>
        <div class="row d-flex justify-content-center mt-4">
          <div class="col-md-6 text-center">
            <img id="logo" :src="logo" class="img-fluid" alt="bcs logo" />
          </div>
        </div>
        <div v-if="showResetForm">
          <div v-if="!resetEmailSent">
            <div class="text-center mt-4 text-28"><med>Reset Your Password</med></div>
            <p class="text-center text-14">
              We'll send you an email with instructions to reset your password.
            </p>

            <div class="row d-flex justify-content-center">
              <div class="col-md-8">
                <!-- Email Input -->
                <div class="mb-3">
                  <label for="resetEmail" class="form-label text-14">
                    <strong>Email</strong>
                  </label>
                  <input
                    type="email"
                    class="form-control"
                    :class="{ 'is-invalid': resetEmailError }"
                    id="resetEmail"
                    v-model="resetEmail"
                    :readonly="route.query.e"
                    placeholder="Enter your email"
                    :disabled="buttonLoading || route.query.e" />
                  <div v-if="resetEmailError" class="invalid-feedback">
                    Please enter a valid email address
                  </div>
                </div>

                <!-- Submit Button -->
                <button
                  @click="sendResetEmail"
                  class="btn btn-primary w-100 mb-3 header-20"
                  :disabled="!resetEmail || buttonLoading"
                  type="button">
                  <div
                    v-if="buttonLoading"
                    class="spinner-border spinner-border-sm me-2"
                    role="status" />
                  <med>Send Reset Password Email</med>
                </button>

                <!-- Back to Login -->
                <div  class="text-center pb-5">
                  <a v-if="!route.query.resetPass" href="#" class="text-14" @click.prevent="toggleResetForm">
                    <med>Back to Login</med>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <!-- Reset Email Sent -->
          <div v-else class="text-center mt-4">
            <div class="text-28"><med>Reset password instructions sent to:</med></div>
            <div class="mt-3 mb-4 text-16">
              <strong>{{ resetEmail }}</strong>
            </div>

            <!-- <a 
              href="#" 
              class="d-block mb-4" 
              @click.prevent="sendResetToNewEmail"
            >
              Send Reset Link to a Different Email
            </a> -->

            <button
              v-if="!route.query.resetPass"
              @click="toggleResetForm"
              class="btn btn-primary w-100 mb-3 mx-auto"
              style="max-width: 300px">
              Back to Login
            </button>

            <p class="text-14 mt-3">
              If you don't receive the email, please
              <a
                href="mailto:info@bcspro.com?subject=Reset Password Email Not Received"
                class="text-14">
                contact BCS Support
              </a>
            </p>
          </div>
        </div>

        <div v-else>
          <div class="row mt-3">
            <div class="col-12 text-center my-4 text-28">
              <med>Welcome! Please sign in.</med>
            </div>
          </div>
          <div class="row d-flex justify-content-center mt-2">
            <div class="col-md-9">
              <form @submit.prevent="login">
                <div class="card px-3" id="form1">
                  <div class="form-data">
                    <div class="forms-inputs mb-3">
                      <label for="username" class="form-label text-14">
                        <strong>Email</strong>
                      </label>
                      <input
                        id="username"
                        autocomplete="off"
                        class="form-control text-14"
                        type="email"
                        placeholder="Enter email"
                        v-model="formState.username"
                        required />
                      <div
                        id="invalidMailMessage"
                        v-if="usernameError && formState.username.length > 0"
                        class="alert alert-danger text-center"
                        role="alert">
                        Please enter a valid email.
                      </div>
                    </div>
                    <div class="forms-inputs pb-3">
                      <label for="password" class="form-label text-14">
                        <strong>Password</strong>
                      </label>
                      <div class="pass-container">
                        <input
                          id="password"
                          autocomplete="off"
                          class="form-control text-14"
                          :type="showPass ? 'input' : 'password'"
                          placeholder="Enter password"
                          v-model="formState.password"
                          required />
                        <span
                          class="view-pass text-primary accent-light text-14 hand-cursor bg-info"
                          @click="showPass = !showPass">
                          <i v-if="showPass" class="bi bi-eye-slash me-1" id="togglePassword"></i>
                          <i v-else class="bi bi-eye me-1" id="togglePassword"></i>
                          <med>&nbsp;{{ showPass ? 'Hide' : 'Show' }}</med>
                        </span>
                      </div>
                      <div id="emailHelp" class="form-text mt-1 text-14">
                        <a href="" @click.prevent="toggleResetForm"><med>Forgot password</med></a>
                      </div>
                    </div>
                    <div class="my-4 text-center">
                      <div
                        id="invalidCredentialsMessage"
                        v-if="showError"
                        class="alert alert-danger text-center"
                        role="alert">
                        Please check your credentials!
                      </div>
                      <button
                        id="submitBUtton"
                        class="btn btn-primary w-100 header-20 mb-5"
                        :disabled="
                          usernameError ||
                          formState.username.length === 0 ||
                          formState.password.length === 0
                        ">
                        Sign In
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row pt-5 mt-5 text-white footer-container">
      <div class="col-12 text-center">
        <p class="text-12">
          2025 All Rights Reserved. Benefits Compliance Solutions is a registered trademark. Patent Pending.
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  body {
    background-color: #2e0c59 !important;
    height: 100vh;
  }

  body.body--light {
    color: black;
  }

  .q-parallax__media > img {
    min-width: 50% !important;
  }

  .card {
    height: 100%;
  }

  .hand-cursor {
    cursor: pointer;
  }

  .container-fluid {
    height: 100vh;
  }

  body {
    background: #000;
  }
  .card {
    border: none;
  }
  .forms-inputs {
    position: relative;
  }
  .forms-inputs .display-1 {
  }

  .pass-container {
    position: relative;
  }

  .view-pass {
    position: relative;
    float: right;
    margin-right: 1rem;
    top: -2.2rem;
    border-radius: 3.2px;
    min-width: 76px;
  }

  span {
    position: absolute;
    top: -18px;
    left: 10px;
    padding: 5px 10px;
    font-size: 15px;
    z-index: 999;
  }
  .forms-inputs input {
    height: 40px;
    border: 2px solid #eee;
  }
  .forms-inputs input:focus {
    box-shadow: none;
    outline: none;
    border: 2px solid #000;
  }
  .btn {
    height: 50px;
  }
  .success-data {
    display: flex;
    flex-direction: column;
  }
  .bxs-badge-check {
    font-size: 90px;
  }
  .loading-overlay {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;
    height: 100vh;
    width: 100vw;
    --bs-bg-opacity: 0.5;
  }
  .img-fluid {
    height: 57px;
  }
  .login-card {
    width: 596px;
    height: fit-content;
  }
  .login-card-container {
    height: 82%;
  }
  .footer-container {
    height: 10%;
  }
</style>